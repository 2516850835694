import icon1 from './images/icon1.png';
import icon2 from './images/icon2.png';
import icon3 from './images/icon3.png';
import icon4 from './images/icon4.png';
import icon5 from './images/icon5.png';
import icon6 from './images/icon6.png';
import img1 from './images/img1.png';
import img2 from './images/img2.png';
import img3 from './images/img3.png';
import img4 from './images/img4.png';
import img5 from './images/img5.png';
import img6 from './images/img6.png';

export const lights = [
  {
    title: '市场解读助手',
    icon: icon1,
    img: img1,
    texts: [
      '发现&解读细分市场特性趋势，提供建议',
      '市场消费者反馈及趋势解读',
      '解决市场&用户&销售&竞品产品趋势',
      '销售趋势特性挖掘与解读，知彼知己',
    ]
  },
  {
    title: '产品创新助手',
    icon: icon2,
    img: img2,
    texts: [
      '解读产品&客群的趋势/功能性的特点挖掘',
      '挖掘产品的市场流行元素，发现更多灵感',
      '基于产品的创新趋势建议和生成创新策略',
    ]
  },
  {
    title: '内容设计助手',
    icon: icon3,
    img: img3,
    texts: [
      '基于场景和创新点的快速生成内容',
      '通过AIGC的文生图，图生图等内容设计能力',
      '覆盖商品营销图、人台图、模特图等内容生成',
    ]
  },
  {
    title: '营销策划助手',
    icon: icon4,
    img: img4,
    texts: [
      '营销策略建议和生成',
      '基于大数据的细分销售场景挖掘和解读',
      '解读消费者流行趋势场景，生成营销策划',
    ]
  },
  {
    title: '智能导购助手',
    icon: icon5,
    img: img5,
    texts: [
      '旅游场景行程推荐',
      '基于消费者画像的商户推荐',
      '基于消费者需求的商品 /服务/权益推荐',
      '基于用户行为的线索生成',
    ]
  },
  {
    title: '知识小秘书',
    icon: icon6,
    img: img6,
    texts: [
      '解读市场研究报告和行业数据，总结关键结论',
      '对市场及行研报告中模式，指标及趋势等元素解析',
      '关于所提供数据的特定问题的问题进行回答',
      '基于解读的报告及内容撰写',
    ]
  },
]