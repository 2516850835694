import "./index.less";
import useViewport from "../../hooks/useViewport";
import { FC } from "react";
import Banner from "./modules/banner";
import Module1 from "./modules/module1";
import "./mobile.less";
import Module2 from "./modules/module2";
import Module3 from "./modules/module3";

const JoinUs: FC = () => {
  const { isMobile } = useViewport();

  return (
    <>
      <div className={`join-us-pc-page${isMobile ? " phone" : ""}`}>
        <Banner />
        <Module1 />
        <Module2 />
        <Module3 />
      </div>
    </>
  );
};
export default JoinUs;
