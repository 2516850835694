import { memo } from "react";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import scjd from "../../images/scjd.svg";
import cpcx from "../../images/cpcx.svg";
import nrsj from "../../images/nrsj.svg";
import touzizhe from "../../images/touzizhe.svg";
import yxch from "../../images/yxch.svg";
import zndg from "../../images/zndg.svg";
const Module3 = memo(() => {
  return (
    <div
      className="common-module-1440 home-module3"
      style={{
        backgroundImage: `url(${imageZip(
          `${CDN_PREFIX}static/frontisportal/banner/module3-bg.png`
        )})`,
      }}
    >
      <div className="common-module-box">
        <div className="common-module-title">{data.title}</div>
        <div className="common-module-row-list col3 gap3 cards-shadow">
          {data.list.map((child) => (
            <div className="col module3-card" key={child.title}>
              <img
                className="col-top-left-icon"
                src={child.icon}
                alt={child.title}
              />
              <div className="col-title left card-title">{child.title}</div>
              <div className="col-line" />
              <ul className="col-list">
                {child.list.map((li) => (
                  <li key={li}>
                    <span className="iconfont icon-check" />
                    <span>{li}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
export default Module3;
const data = {
  title: "探索实践基于衔远自研大模型的业务应用",
  list: [
    {
      title: "市场洞察",
      list: [
        "发现&解读细分市场特性趋势，提供建议",
        "市场消费者反馈及趋势解读",
        "解决市场&用户&销售&竞品产品趋势",
        "销售趋势特性挖掘与解读，知彼知己",
      ],
      icon: scjd,
    },
    {
      title: "内容设计",
      list: [
        "基于场景和创新点的快速生成内容",
        "通过AIGC的文生图，图生图等内容设计能力， 覆盖商品营销图、人台图、模特图等内容生成",
      ],
      icon: nrsj,
    },
    {
      title: "营销策划",
      list: [
        "营销策略建议和生成",
        "基于大数据的细分销售场景挖掘和解读",
        "解读消费者流行趋势场景，生成营销策划",
      ],
      icon: yxch,
    },
    {
      title: "科学发现",
      list: [
        "构建科研领域专业大模型",
        "高质量科学假设提出",
        "基于动力学的知识多维度验证能力，从而得出高质量科学结论",
      ],
      icon: cpcx,
    },

    {
      title: "建筑行业计划管理",
      list: [
        "计划管理专业大模型",
        "各级计划生成能力",
        "计划执行延误预警能力",
        "计划延误纠偏能力",
      ],
      icon: zndg,
    },
    {
      title: "知识管理",
      list: [
        "解读市场研究报告和行业数据，总结关键结论",
        "对市场及行研报告模式，指标及趋势等元素解析",
        "对于所提供数据的特定问题进行回答",
        "基于解读的报告及内容撰写",
      ],
      icon: touzizhe,
    },
  ],
};
