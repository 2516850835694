import React, {
  FC,
  memo,
  useState,
  useRef,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { Link, useLocation } from "react-router-dom";
import Routes, { IRoutes } from "../../../router";
import { AppContext } from "../../../context";
import { BASE_ROUTE_NAME } from "../../../config/index";
import logoBlack from "../../../images/logo-black.svg";
import { CommonContext } from "../../../common/commonContext";
import "./index.less";

const HeaderPc: FC = memo(() => {
  const { navStyle } = useContext(AppContext);
  const { setShowService, showService } = useContext(CommonContext);
  const [parentPath, setParentPath] = useState("");
  const [showSubMenu, setShowSubMenu] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const routeMap = useRef<Record<string, IRoutes>>({});
  const routeModalData = useRef<Record<string, IRoutes[]>>(
    Routes?.reduce<Record<string, IRoutes[]>>((res, cur) => {
      routeMap.current[cur.path] = cur;
      if (cur.parentPath && !cur.hidden && !cur.hiddenPc) {
        (res[cur.parentPath] ||= []).push(cur);
      }
      return res;
    }, {})
  );
  const routeSubData = useMemo(() => {
    const _routeSubData: Record<string, Record<string, IRoutes[]>> = {};
    Routes.forEach((ele) => {
      routeMap.current[ele.path] = ele;
      if (ele.parentPath && !ele.hidden && !ele.hiddenPc) {
        ((_routeSubData[ele.parentPath] ||= {})[ele.subName || "null"] ||=
          []).push(ele);
      }
    });
    return _routeSubData;
  }, []);
  useEffect(() => {
    if (routeMap.current?.[pathname]?.parentPath) {
      setParentPath(routeMap.current?.[pathname]?.parentPath as string);
    } else if (pathname.indexOf(`/news/detail`) > -1) {
      setParentPath(`${BASE_ROUTE_NAME}/news`);
    } else {
      setParentPath("");
    }
  }, [pathname]);

  return (
    <>
      <header className={`pc-head ${navStyle}`}>
        <div className="head-main">
          <div className="left">
            <img className="logo" src={logoBlack} alt="" />
            <ul className="navs">
              {Routes?.map((item) => {
                if (item.parentPath || item.hidden) return null;
                return (
                  <li
                    key={item.path}
                    onMouseEnter={() => setShowSubMenu(true)}
                    onMouseLeave={() => setShowSubMenu(false)}
                    className={
                      (routeModalData.current?.[item.path]?.length &&
                        routeModalData.current[item.path].find(
                          (ele) => ele.path === pathname
                        )) ||
                      (item.redirect || item.path) === pathname ||
                      parentPath === item.path
                        ? "navActive"
                        : ""
                    }
                  >
                    <Link to={item.redirect || item.path}>{item.name}</Link>
                    {showSubMenu && routeSubData?.[item.path] && (
                      <ul className="nav-children">
                        {Object.values(routeSubData?.[item.path]).map(
                          (sub, index) => {
                            return (
                              <React.Fragment key={index}>
                                {sub[0].subName && (
                                  <li className="nav-children-title">
                                    {sub[0].subName}
                                  </li>
                                )}
                                {sub.map((item) => {
                                  return (
                                    <li
                                      key={item.path}
                                      className={`${
                                        pathname === item.path ? "active" : ""
                                      }${item.disabled ? " disabled" : ""}`}
                                    >
                                      {item.disabled ? (
                                        <>
                                          <em>{item.name}</em>
                                          <span className="coming-soon-tips">
                                            敬请期待
                                          </span>
                                        </>
                                      ) : (
                                        <Link
                                          onClick={() => setShowSubMenu(false)}
                                          to={item.path}
                                        >
                                          {item.name}
                                        </Link>
                                      )}
                                    </li>
                                  );
                                })}
                              </React.Fragment>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className="common-ghost-primary-btn"
            onClick={() => setShowService?.(showService ? "" : "click")}
          >
            咨询与合作
          </div>
        </div>
      </header>
    </>
  );
});

export default HeaderPc;
