import { memo } from "react";
import "./index.less";
import { CDN_PREFIX } from "../../../../utils/constants";
import { imageZip } from "../../../../utils/utils";
const imgBaseSrc = `${CDN_PREFIX}static/frontisportal/enterprises/`;
const Module3 = memo(() => {
  return (
    <div className="common-module-full marketing-module3">
      <div className="common-module-box common-module-padding60 ">
        <div className="common-module-title">{data.title}</div>
        <div className="common-module-row-list gap24 col5 images">
          {data.images.map((child) => (
            <img key={child} src={imageZip(imgBaseSrc + child)} alt="" />
          ))}
        </div>
      </div>
    </div>
  );
});
export default Module3;
const data = {
  title: "100,000+ 企业客户的一致选择",
  images: [
    "00.png",
    "01.png",
    "02.png",
    "03.png",
    "04.png",
    "05.png",
    "06.png",
    "07.png",
    "08.png",
    "09.png",
    "10.png",
    "11.png",
    "12.png",
    "13.png",
  ],
};
