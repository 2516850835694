import { memo } from "react";
import { CDN_PREFIX } from "../../../../utils/constants";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
const Banner = memo(() => {
  return (
    <div
      className="common-banner home-pc-banner"
      style={{ backgroundImage: `url(${data.bg})` }}
    >
      <div className="common-banner-main">
        <div className="common-banner-box">
          <div className="common-banner-title">{data.title}</div>
          <div className="common-banner-line" />
          <div className="common-banner-text">{data.text}</div>
        </div>
      </div>
    </div>
  );
});

export default Banner;

const data = {
  bg: imageZip(
    `${CDN_PREFIX}static/frontisportal/banner/banner1.png?time=11061722`
  ),
  title: `全球智能化服务引领者`,
  // title: `全球领先的
  // 智能化服务引领者`,
  text: `我们致力于推动 ExpertAGI 的实现及其落地`,
};
