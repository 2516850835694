// 导入所需组件
import HomePage from "../pages/homePage";
import ProductIntroduction from "../pages/productIntroduction";
import Solution from "../pages/solution";
import Resource from "../pages/resource";
import ResourceCenter from "../pages/resourceCenter";
import AboutUs from "../pages/aboutUs";
import JoinUs from "../pages/joinUs";
import NewsDetail from "../pages/newsDetail";
import Contact from "../pages/contact";
import OfficialContact from "../pages/officialContact";
import GroundPage from "../pages/groundPage";
// import Conference0425 from "../pages/conference";
import { BASE_ROUTE_NAME } from "../config/index";
import Science from "../pages/science";
import MarketingProducts from "../pages/marketingProducts";
import MassProducts from "../pages/massProducts";
import { AllScene, ExpertScene, PrivateScene } from "../pages/solutionScene";

export interface IRoutes {
  path: string;
  exact: boolean;
  name: string;
  component?: any;
  children?: Array<IRoutes>;
  redirect?: string;
  parentPath?: string;
  hidden?: boolean;
  subName?: string;
  disabled?: boolean;
  hiddenPc?: boolean;
}
// const OldRoutes: IRoutes[] = [
//   {
//     path: `${BASE_ROUTE_NAME === "" ? "/" : BASE_ROUTE_NAME}`,
//     exact: true,
//     name: "",
//     component: Conference0425,
//     hidden: true,
//   },
// ];
export const Routes: IRoutes[] = [
  {
    path: `${BASE_ROUTE_NAME === "" ? "/" : BASE_ROUTE_NAME}`,
    exact: true,
    name: "首页",
    component: HomePage,
  },
  {
    path: `${BASE_ROUTE_NAME}/product`,
    exact: true,
    name: "产品介绍",
    component: ProductIntroduction,
    redirect: `${BASE_ROUTE_NAME}/product/marketing`,
  },
  {
    path: `${BASE_ROUTE_NAME}/product/marketing`,
    exact: true,
    name: "AI 营销产品",
    component: MarketingProducts,
    parentPath: `${BASE_ROUTE_NAME}/product`,
  },
  {
    path: `${BASE_ROUTE_NAME}/product/mass`,
    exact: true,
    name: "MaaS 产品",
    component: MassProducts,
    parentPath: `${BASE_ROUTE_NAME}/product`,
  },
  {
    path: `${BASE_ROUTE_NAME}/solution`,
    exact: true,
    name: "解决方案",
    component: Solution,
    redirect: `${BASE_ROUTE_NAME}/solution/allEmployee`,
  },
  // {
  //   path: `${BASE_ROUTE_NAME}/solution/ft`,
  //   exact: true,
  //   name: "时尚潮流行业",
  //   component: MakeUp,
  //   parentPath: `${BASE_ROUTE_NAME}/solution`,
  // },
  {
    path: `${BASE_ROUTE_NAME}/solution/allEmployee`,
    exact: true,
    name: "全员营销",
    component: AllScene,
    parentPath: `${BASE_ROUTE_NAME}/solution`,
    subName: "场景",
  },
  {
    path: `${BASE_ROUTE_NAME}/solution/expert`,
    exact: true,
    name: "达人营销",
    component: ExpertScene,
    parentPath: `${BASE_ROUTE_NAME}/solution`,
    subName: "场景",
  },
  {
    path: `${BASE_ROUTE_NAME}/solution/privateDomain`,
    exact: true,
    name: "私域营销",
    component: PrivateScene,
    parentPath: `${BASE_ROUTE_NAME}/solution`,
    subName: "场景",
  },
  {
    path: `${BASE_ROUTE_NAME}/solution/science`,
    exact: true,
    name: "科学发现解决方案",
    component: Science,
    parentPath: `${BASE_ROUTE_NAME}/solution`,
    subName: "行业",
  },
  {
    path: `${BASE_ROUTE_NAME}/solution/supplyChain`,
    exact: true,
    name: "供应链解决方案",
    component: Science,
    parentPath: `${BASE_ROUTE_NAME}/solution`,
    subName: "行业",
    disabled: true,
  },
  {
    path: `${BASE_ROUTE_NAME}/solution/constructionIndustry `,
    exact: true,
    name: "建筑行业解决方案",
    component: Science,
    parentPath: `${BASE_ROUTE_NAME}/solution`,
    subName: "行业",
    disabled: true,
  },
  // {
  //   path: `${BASE_ROUTE_NAME}/solution/fb`,
  //   exact: true,
  //   name: "食品饮料",
  //   component: Solution,
  //   parentPath: `${BASE_ROUTE_NAME}/solution`,
  //   hiddenPc: true,
  // },
  // {
  //   path: `${BASE_ROUTE_NAME}/solution/fmcg`,
  //   exact: true,
  //   name: "快消零售",
  //   component: FastConsumption,
  //   parentPath: `${BASE_ROUTE_NAME}/solution`,
  //   hiddenPc: true,
  // },
  // {
  //   path: `${BASE_ROUTE_NAME}/solution/mxx`,
  //   exact: true,
  //   name: "时尚潮流",
  //   component: Mxx,
  //   parentPath: `${BASE_ROUTE_NAME}/solution`,
  //   hiddenPc: true,
  // },
  {
    path: `${BASE_ROUTE_NAME}/Resource`,
    exact: true,
    name: "",
    hidden: true,
    component: Resource,
  },
  {
    path: `${BASE_ROUTE_NAME}/news`,
    exact: true,
    name: "新闻中心",
    component: ResourceCenter,
  },
  {
    path: `${BASE_ROUTE_NAME}/news/detail/:id`,
    exact: true,
    name: "",
    component: NewsDetail,
    hidden: true,
    parentPath: `${BASE_ROUTE_NAME}/news`,
  },
  {
    path: `${BASE_ROUTE_NAME}/about`,
    exact: true,
    name: "关于我们",
    component: AboutUs,
    redirect: `${BASE_ROUTE_NAME}/about/introduce`,
  },
  {
    path: `${BASE_ROUTE_NAME}/about/introduce`,
    exact: true,
    name: "公司介绍",
    component: AboutUs,
    parentPath: `${BASE_ROUTE_NAME}/about`,
  },
  {
    path: `${BASE_ROUTE_NAME}/about/join`,
    exact: true,
    name: "加入我们",
    component: JoinUs,
    parentPath: `${BASE_ROUTE_NAME}/about`,
  },
  {
    path: `${BASE_ROUTE_NAME}/contact`,
    exact: true,
    name: "",
    component: Contact,
    hidden: true,
  },
  {
    path: `${BASE_ROUTE_NAME}/officialContact`,
    exact: true,
    name: "",
    component: OfficialContact,
    hidden: true,
  },
  {
    path: `${BASE_ROUTE_NAME}/groundPage`,
    exact: true,
    name: "",
    component: GroundPage,
    hidden: true,
  },
];
export default Routes;
