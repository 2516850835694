import useViewport from "../../../../hooks/useViewport";

import img from "../../images/img.png";

import img2 from "../../images/img2.png";

import "./media.less";
import "./index.less";
const data = [
  {
    title: "AI技术趋势",
    desc: [
      "企业内部知识",
      "文献/论文/行业论坛",
      "技术内容理解与解读",
      "对话式知识问答",
    ],
    img: img,
  },
  {
    title: "AI配方",
    desc: ["实时合规校验", "配方信息生成", "实验预测", "成本预测"],
    img: img2,
  },
  // {
  //   title: "AI技术趋势",
  //   desc: [
  //     "企业内部知识",
  //     "文献/论文/行业论坛",
  //     "技术内容理解与解读",
  //     "对话式知识问答",
  //   ],
  //   img: img3,
  // },
];
const AiMain = () => {
  const { isMobile } = useViewport();

  return (
    <div className={`${isMobile ? "ai-main-mobile" : "ai-main"}`}>
      <div className="w1200">
        <h3  className="title">ProductGPT大模型驱动研发智能化</h3>
        {data.map((data,i) => {
          return (
            <div className="ai-box " key={i}>
              <div className="left">
                <h3>{data.title}</h3>
                <ul>
                  {data.desc.map((desc) => {
                    return (
                      <li className="ai-li" key={desc}>
                        {desc}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="right">
                <img src={data.img} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default AiMain;
