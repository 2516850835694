import { memo } from "react";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import "./index.less";
const formatUrl = (src: string) => imageZip(`${CDN_PREFIX}${src}`);
const Banner = memo(() => {
  return (
    <div
      className="fr-science-pc-banner"
      style={{
        backgroundImage: `url(${formatUrl(data.bgBg)})`,
      }}
    >
      <div
        className="bg"
        style={{
          backgroundImage: `url(${formatUrl(data.bg)})`,
        }}
      ></div>
      <div className="content">
        <img
          className="time-line-img"
          src={formatUrl(data.timeLineBg)}
          alt={data.timeLineAlt}
        />
      </div>
    </div>
  );
});
// 306
// 600
export default Banner;
const data = {
  bgBg: "static/frontisportal/science/science-bg-bg.png",
  bg: "static/frontisportal/science/bg.png",
  timeLineBg: "static/frontisportal/banner/since-banner-pic.png?time=1031",
  timeLineAlt:
    "基于经验事实的归纳法、基于抽象理论的演绎分析、计算机参与的仿真与模拟、数据密集型科学；人工智能/大语言模型是促进科学发现的重要驱动力，代表新的科研范式；灵光一闪的、假设归纳；可以变化的、理论模型；真实世界数据、发现研究客体",
};
