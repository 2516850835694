import { FC, memo } from "react";
import "./index.less";
import { welfareData } from "../../data";

const Module2: FC = memo(() => {
  return (
    <div className="common-module-full join-us-module2">
      <div className="common-module-box">
        <div className="common-module-text">更多福利等你发现</div>
        <div className="common-module-title">公司福利一览</div>
        <ul className="benefit-list">
          {welfareData.map((child) => (
            <li key={child.name}>
              <img src={child.icon} alt={child.name} />
              <p>{child.name}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});
export default Module2;
