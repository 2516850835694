import React, { FC, memo, useCallback, useEffect, useState } from "react";
import "./index.less";
import axios, { AxiosResponse } from "axios";
import { REACT_APP_REQUEST_URL } from "../../../../env";
import { depData, IDepItem, IJobItem } from "../../data";
import useViewport from "../../../../hooks/useViewport";

const Module1: FC = memo(() => {
  const { isMobile } = useViewport();
  const [classification, setClassification] = useState<IDepItem[]>();
  const [classActive, setClassActive] = useState<IDepItem>();
  const [jods, setJobs] = useState<Record<string, IJobItem[]>>({});
  const [jobDetail, setJobDetail] = useState<IJobItem>();
  const [active, _setActive] = useState<IJobItem>();
  const fetchScp = async (item: IJobItem) => {
    setJobDetail(undefined);
    if (item?.id) {
      const res = await axios(`${REACT_APP_REQUEST_URL}/job/${item.id}`);
      setJobDetail(res.data.data);
    }
  };
  const setActive = useCallback((v: IJobItem) => {
    _setActive(v);
    fetchScp(v);
  }, []);
  const fetchJob = useCallback(async () => {
    const res = await axios(`${REACT_APP_REQUEST_URL}/department`);
    if (res?.data?.data?.length) {
      if (!res.data.data.find((ele: IDepItem) => `${ele.id}` === `20`)) {
        res.data.data.push({ id: 20, departmentName: `其他` });
      }
      const jobRes = await Promise.all<
        Promise<AxiosResponse<{ data: { records: IDepItem[] } }>>
      >(
        res.data.data.map((child: IDepItem) =>
          axios.get(`${REACT_APP_REQUEST_URL}/job`, {
            params: {
              departmentId: child?.id,
              departmentName: child?.departmentName,
              keyword: "",
              pageNum: "1",
              pageSize: "100",
            },
          })
        )
      );

      setClassification(res.data.data);
      setClassActive(res.data.data[0]);
      setActive(jobRes?.[0]?.data?.data?.records?.[0]);
      setJobs(
        (res.data.data as IDepItem[])?.reduce<Record<string, IJobItem[]>>(
          (pre, cur, index) => ({
            ...pre,
            [cur.id as string]: jobRes?.[index]?.data?.data?.records,
          }),
          {}
        )
      );
      jobRes?.reduce<IJobItem[]>(
        (pre, cur) => [...pre, ...cur.data?.data?.records],
        []
      );
    }
  }, []);
  useEffect(() => {
    fetchJob();
  }, []);
  return (
    <div
      className={`common-module-full common-module-padding-bottom join-us-module1`}
    >
      <div className="common-module-box">
        <div className="common-module-title">{data.title}</div>
        <div className="common-module-text">{data.text}</div>
      </div>
      <div className="jobs-box">
        <div className="job-left">
          <div className="job-left-main">
            <div className="job-left-content">
              {classification?.map((cla) => (
                <React.Fragment key={cla.id}>
                  <div
                    className={`cla-name${
                      classActive?.id === cla.id ? " active" : ""
                    }`}
                    onClick={() => {
                      if (isMobile) {
                        setClassActive(cla);
                        setActive(jods?.[cla.id as string]?.[0]);
                      }
                    }}
                  >
                    {cla.departmentName}
                  </div>
                  {!isMobile &&
                    jods?.[cla.id as string]?.map((child) => (
                      <div
                        key={child.id}
                        className={`job-item${
                          child.id === active?.id ? " active" : ""
                        }`}
                        onClick={() => setActive(child)}
                      >
                        {child.jobName}
                      </div>
                    ))}
                </React.Fragment>
              ))}
              {isMobile && (
                <div className="job-box">
                  <div className="job-box-content">
                    {jods?.[classActive?.id as string]?.map((child) => (
                      <div
                        key={child.id}
                        className={`job-item${
                          child.id === active?.id ? " active" : ""
                        }`}
                        onClick={() => setActive(child)}
                      >
                        {child.jobName}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="job-right">
          <div className="job-right-content">
            <div className="job-head">
              <div className="job-name">{jobDetail?.jobName}</div>
              <div className="job-email">联系邮箱：hr-public@frontis.cn</div>
            </div>
            <div className="job-cotent">
              {depData.map(
                (child) =>
                  jobDetail?.[child.key as keyof IJobItem] && (
                    <React.Fragment key={child.name}>
                      <div className="job-row-name">{child.name}</div>
                      <div
                        className="job-row-content"
                        dangerouslySetInnerHTML={{
                          __html:
                            (jobDetail?.[
                              child.key as keyof IJobItem
                            ] as string) || "",
                        }}
                      />
                    </React.Fragment>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Module1;
const data = {
  title: "热招岗位",
  text: `全职&实习任你选`,
};
