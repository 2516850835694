import { useEffect } from "react";

import { setHeader } from "../config/setHeader";

export const useSetHeader = ({
  title,
  description,
  keywords,
}: {
  title?: string;
  description?: string;
  keywords?: string;
}) => {
  useEffect(() => {
    if (!title || !description || !keywords) {
      setHeader({
        title: "衔远科技，全球智能化服务引领者",
        description: "我们致力于推动 ExpertAGI 的实现及其落地",
        keywords:
          "衔远科技,周伯文,摹小仙,MODI,国产AI大模型,AI营销,AI商拍, ProductGPT,ProductDiffusion,慕小仙",
      });
      return;
    }
    setHeader({
      title,
      description,
      keywords,
    });
  }, [title, description, keywords]);
};
