import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import { memo } from "react";
import "./index.less";
const banner = imageZip(
  `${CDN_PREFIX}static/frontisportal/introduce/introduce-bg.png?time=11041827`
);
const Banner = memo(() => {
  return (
    <>
      <div className="common-banner-top" />
      <div className="common-banner">
        <img
          className="pic"
          src={banner}
          alt="全球智能化服务引领者\全球领先的通专融合技术路线、20多年人工智能技术沉淀、企业5D赋能：Discover, Define, Design, Develop, Distribute、领先的AI技术和服务能力 让产品研发协同效率提升50%"
        />
      </div>
    </>
  );
});
export default Banner;
