import { memo } from "react";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import "./index.less";

const Module3 = memo(() => {
  return (
    <div className="common-module-box  common-module-padding-bottom maas-module3">
      <div className="common-module-title">{data.title}</div>
      <img className="common-module-pic" src={data.pic} alt={data.text} />
    </div>
  );
});
export default Module3;
const data = {
  title: `衔远通专融合新一代人工智能平台`,
  text: `行业大模型、基础大模型、机器学习模型；大小模型协同、多智能体协同、工具协同、人机交互；风险管控、研发提效、智能营销、知识管理、科学发现、内容创意、业务预测、数据分析；高性能计算、高性能网络、高性能云存储、加速框架、国产计算架构`,
  pic: imageZip(`${CDN_PREFIX}static/frontisportal/maas/maas3.png`),
};
