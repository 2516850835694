import { memo, useMemo } from "react";
import "./index.less";
import "./mobile.less";

interface ISmallTabs {
  options?: { label?: string; key: string }[];
  active?: string;
  onChange?: (v: string) => void;
  trigger?: "hover" | "click";
}
const SmallTabs = memo<ISmallTabs>(
  ({ options, active = 0, onChange, trigger = "click" }) => {
    const index = useMemo(
      () => options?.findIndex((ele) => ele.key === active) || 0,
      [options, active]
    );
    return (
      <div className="fr-small-tabs">
        {options?.map((child) => (
          <div
            className={`tabs-item${active === child.key ? " active" : ""}`}
            key={child.key}
            onClick={() => onChange?.(child.key)}
            onMouseEnter={() => trigger === "hover" && onChange?.(child.key)}
          >
            {child.label}
          </div>
        ))}
        <div className="line-box">
          <div
            className="line"
            style={{
              left: `${index * (100 / (options?.length || 0))}%`,
              width: `${100 / (options?.length || 0)}%`,
            }}
          />
        </div>
      </div>
    );
  }
);

export default SmallTabs;
