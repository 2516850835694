import icon1 from "../../images/product-icon1.svg";
import icon2 from "../../images/product-icon2.svg";
import icon3 from "../../images/product-icon3.svg";
import icon4 from "../../images/product-icon4.svg";
import icon5 from "../../images/product-icon5.svg";
export const data = {
  title: `产品功能亮点`,
  tabs: [
    {
      title: "模特商拍",
      icon: icon1,
    },
    {
      title: "商品商拍",
      icon: icon2,
    },
    {
      title: "AI 营销",
      icon: icon3,
    },
    {
      title: "爆款爆改",
      icon: icon4,
    },
    {
      title: "AI 工具",
      icon: icon5,
    },
  ],
};
export const module2data = [
  {
    title: "一键生成AI模特试衣大片",
    desc: "支持不同国籍、年龄AI模特生成，还有多种场景供选择；降低商拍成本，再无场地限制",
    tags: ["真实人像", "质感场景", "模特定制"],
    imgs: ["img1", "img2", "img3", "img4"],
    url: [
      "/model-photo-new",
      "/human-figure-image",
      "/wig-image",
      "/customized-publicity",
    ],
    items: [
      {
        label: `真人图`,
        key: "0",
      },
      {
        label: `人台图`,
        key: "1",
      },
      {
        label: `假发图`,
        key: "2",
      },
      {
        label: `定制模特`,
        key: "3",
      },
    ],
  },
  {
    title: "让每个产品都拥有高质感展示图",
    desc: "不再受限于拍摄时间拍摄地点，几秒钟生成媲美专业的商品图",
    tags: ["优质模版", "精品复刻"],
    imgs: ["img5", "img6"],
    url: ["/ai-product-image", "/ai-product-image?scene=3"],
    items: [
      {
        label: `优质模版`,
        key: "0",
      },
      {
        label: `精品复刻`,
        key: "1",
      },
    ],
  },
  {
    title: "社交媒体营销素材一键创作",
    desc: "根据商品特性和时下热点生成极具传播性的社媒图文内容，“蹭”的快人一步",
    tags: ["产品种草", "品牌宣传"],
    imgs: ["img8", "img7"],
    url: ["/content-calendar", "/content-hotspot"],
    type: "right",
    items: [
      {
        label: `营销日历`,
        key: "0",
      },
      {
        label: `热点营销`,
        key: "1",
      },
    ],
  },
  {
    title: "爆款内容一键轻松复刻",
    desc: "支持结合不同营销需求，批量创作属于你的爆款内容，紧跟爆款流量快车",
    tags: ["爆款图文", "爆款脚本"],
    imgs: ["img9", "img10"],
    url: ["/rewrite-image", "/rewrite-text"],
    type: "right",
    items: [
      {
        label: `爆图爆改`,
        key: "0",
      },
      {
        label: `爆文爆改`,
        key: "1",
      },
    ],
  },
  {
    title: "个性化AI创作工具",
    desc: "提供自由度更高的创作工具，助力你的营销物料制作，一个平台满足多个需求",
    tags: ["多种模型", "操作简单", "秒级生成"],
    imgs: ["img11", "img12", "img13", "img14"],
    type: "right",
    url: ["", "/ai-cutout", "/product-bg", ""],
    items: [
      {
        label: `图片生成`,
        key: "0",
      },
      {
        label: `一键抠图`,
        key: "1",
      },
      {
        label: `商品替换`,
        key: "2",
      },
      {
        label: `视频生成`,
        key: "3",
      },
    ],
  },
];
