import { useEffect, useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { REACT_APP_REQUEST_URL } from "../../env";
import { BASE_ROUTE_NAME } from "../../config/index";
import useViewport from "../../hooks/useViewport";
import { setHeader } from "../../config/setHeader";
import "./media.less";
import "./index.less";
import "./quill.snow.css";

interface INewDetails {
  content?: string;
  coverPlan?: string;
  createTime?: string;
  createUser?: string;
  id?: number;
  readCount?: number;
  region: {
    desc?: string;
    code?: string;
  };
  sort?: number;
  status: {
    desc?: string;
    code?: string;
  };
  subTitle?: string;
  summary?: string;
  title?: string;
  top?: boolean;
  updateTime?: string;
  updateUser?: string;
  nextId?: string | number;
  nextTitle?: string;
  preId?: string | number;
  preTitle?: string;
}

const NewsDetail = () => {
  const state = useParams<{ id: string }>();
  const { isMobile } = useViewport();
  const history = useHistory();
  const [newList, setNewList] = useState<Array<INewDetails>>([]);
  const [newDetails, setNewDetails] = useState<INewDetails>();
  const [loading, setLoading] = useState(false);
  const fetchData = (id: number) => {
    setLoading(true);
    setNewDetails(undefined);
    axios
      .get(`${REACT_APP_REQUEST_URL}/newsinfo/${id}`)
      .then((res) => {
        setNewDetails(res.data.data);
        const { spiderDescription, spiderKeywords, spiderTitle } =
          res.data.data;
        setHeader({
          title: spiderTitle,
          description: spiderDescription,
          keywords: spiderKeywords,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    axios
      .get(`${REACT_APP_REQUEST_URL}/newsinfo?status=PUBLISHED`)
      .then((res) => {
        let newsList: any = res.data.data.records
          .filter((item: any) => item.status.code === "PUBLISHED")
          .sort((a: any, b: any) => b.readCount - a.readCount)
          .slice(0, 5);
        setNewList(newsList);
      });
  };
  const onGoDetails = (id: number) => {
    history.push(`${BASE_ROUTE_NAME}/news/detail/${id}`);
    window.scrollTo(0, 0);
    fetchData(id);
  };
  useEffect(() => {
    fetchData(Number(state.id) as number);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!newDetails?.content) return;
    setTimeout(() => {
      hideVideoDownload()
    }, 500);
  }, [newDetails?.content])

  const hideVideoDownload = () => {
    let iframes: any = document.getElementsByClassName('ql-video')
    for (let i of iframes) {
      let url = i.getAttribute('src');
      let video = document.createElement('video');
      video.setAttribute('controls', 'true');
      video.setAttribute('name', 'media');
      video.setAttribute('controlslist', 'nodownload');
      video.setAttribute('src', url);
      video.setAttribute('class', 'ref-video');
      let parentDiv = i.parentNode;
      parentDiv.insertBefore(video, i);
    }

    // 删除iframe video
    document.querySelectorAll('iframe.ql-video').forEach(i => i.remove())
  }

  return (
    <div
      className={`${loading && "page-loading"}  ${
        isMobile ? "news-detail-mobile" : "news-detail"
      }`}
    >
      <div className="header-bg"></div>
      <div className="detail-content">
        <ul className="bread-crumb">
          <li onClick={() => history.push(`${BASE_ROUTE_NAME}/news`)} style={{ cursor: 'pointer' }}>新闻列表&emsp;{">"}&emsp;</li>
          <li>新闻详情</li>
        </ul>
        <div className="detail-module">
          <div className="detail-left">
            <div className="title">{newDetails?.title}</div>
            <div className='infos'>
              <div className='date'>
                <span className='iconfont icon-time'></span>
                {newDetails?.createTime}
              </div>
              <div className='read-num'>
                <span className='iconfont icon-eyes'></span>
                {newDetails?.readCount}
              </div>
            </div>
            <div className="summary">{newDetails?.summary}</div>
            <div className="ql-editor">
              <div
                dangerouslySetInnerHTML={{
                  __html: newDetails?.content || "",
                }}
                className="detail-text"
              ></div>
            </div>
            {(newDetails?.preId || newDetails?.nextId) && (
              <div className="detail-bottom">
                {newDetails.preId && <div className="pre-item" onClick={() => onGoDetails(newDetails.preId as number)}>上一篇：<span>{newDetails?.preTitle}</span></div>}
                {newDetails?.preId && newDetails?.nextId && <div className="line"></div>}
                {newDetails.nextId && <div className="next-item" onClick={() => onGoDetails(newDetails.nextId as number)}>下一篇：<span>{newDetails?.nextTitle}</span></div>}
              </div>
            )}
          </div>
          <div className="detail-right">
            <div className="detail-right-content">
              <h2>相关推荐</h2>
              <ul>
                {newList?.map((item) => {
                  return (
                    <li
                      key={item.id}
                      onClick={() => onGoDetails(item.id as number )}
                    >
                      <img src={item.coverPlan} alt="" />
                      <h4>{item.title}</h4>
                      <span>
                        {moment(item.createTime).format("YYYY年MM月DD日")}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <span className="loading-icon" />
    </div>
  );
};
export default withRouter(NewsDetail);
