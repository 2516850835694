import { memo } from "react";
import "./index.less";
import "./mobile.less";

interface IBigIconTabs {
  options?: { title?: string; icon?: string }[];
  active?: number;
  onChange?: (v: number) => void;
}
const BigIconTabs = memo<IBigIconTabs>(({ options, active = 0, onChange }) => {
  return (
    <div className="fr-bg-icon-tabs">
      {options?.map((child, index) => (
        <div
          className={`tabs-item${active === index ? " active" : ""}`}
          key={child.title}
          onClick={() => onChange?.(index)}
        >
          <img className="icon" src={child.icon} alt={child.title} />
          <span className="name">{child.title}</span>
        </div>
      ))}
      <div
        className="line"
        style={{
          left: `${active * (100 / (options?.length || 0))}%`,
          width: `${100 / (options?.length || 0)}%`,
        }}
      />
    </div>
  );
});

export default BigIconTabs;
