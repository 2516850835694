import { memo } from "react";
import num2 from "../../images/02.svg";
import "./index.less";

const Module1 = memo(() => {
  return (
    <div className="fr-science-pc-module1">
      <div className="content">
        <img className="num" src={num2} alt={data.title} />
        <div className="title">{data.title}</div>
        <div className="bot">
          {data.list.map((child) => (
            <div key={child.title} className="bot-item">
              <div className="bot-title">{child.title}</div>
              {child.text && <div className="bot-text">{child.text}</div>}
              <ol>
                {child.ol.map((li, index) => (
                  <li key={index}>
                    <div>
                      {li.blue && <span className="blue">{li.blue}</span>}
                      <span>{li.text}</span>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
export default Module1;
const data = {
  title: "人工智能驱动的科学发现：现状和挑战",
  list: [
    {
      title: "科学发现现状",
      text: "大模型参与指导的科学实验，例如：机器人做化学、生物实验，AIDD等：",
      ol: [
        {
          text: "较低复杂度的任务目前主要是以流程自动化为主；较高复杂度任务（例如复杂数学问题）还是主要以科学家为主",
          blue: "",
        },
        {
          text: "目前更多聚焦在单智能体和单学科突破",
        },
        {
          text: "没有较好的大规模验证和自动化湿实验体系 ",
        },
        {
          text: "技术成熟度低",
        },
      ],
    },
    {
      title: "新挑战（局限性）",
      ol: [
        {
          blue: "高度专业化缺失",
          text: "通用大模型缺乏处理高复杂度科学问题的能力",
        },
        {
          blue: "知识发现跃迁难",
          text: "从单点知识推理到元知识发现的过程跃迁困难，从知识发现的单体模块出现到通用科学多智能体原型的跃迁存在挑战",
        },
        {
          blue: "知识验证效率低",
          text: "新发现的知识难以快速、高效地进行结论筛选、证据搜集和干实验验证困难",
        },
      ],
    },
  ],
};
