import { useState } from 'react';
import { useHistory } from "react-router-dom";
import AlterWindow from '../../layout/components/alterWindow';
import ContactFormMobile from '../../layout/components/contactFormMobile';
import useViewport from "../../hooks/useViewport";
import bgFrom from './images/bgForm.png';
import calendar from './images/calendar-check-fill.png';
import calendarMobile from './images/calendar-check-fill-mobile.png';
import isMobileLogo from './images/isMobileLogo.png';
import logo from './images/logo.png';
import { BASE_ROUTE_NAME } from "../../config";
import './index.less';
import './media.less';


const OfficialContact = () => {
    const { isMobile } = useViewport();
    const history = useHistory()
    const [altSubmit, setAltSubmit] = useState(false);
    const submitDom = altSubmit ? (
        <div className={isMobile ? 'submit-dom-mobile' : 'submit-dom'}>
            <div className='submit-success'>
                <img src={isMobile ? calendarMobile : calendar} alt="" />
                <h2>已提交</h2>
                <p>我们将于2个工作日内与您取得联系</p>
                {isMobile && <div onClick={() => history.push(BASE_ROUTE_NAME.trim() !== '' ? BASE_ROUTE_NAME : `/${BASE_ROUTE_NAME}`)} >点击进入官网</div>}
            </div>
        </div>
    ) : null

    return <>
        <div className='official-contact-header'>
            <div
                style={{ backgroundImage: `url(${isMobile ? isMobileLogo : logo})` }}
                onClick={() => history.push(BASE_ROUTE_NAME.trim() !== '' ? BASE_ROUTE_NAME : `/${BASE_ROUTE_NAME}`)}
            ></div>
        </div>
        {isMobile ? <ContactFormMobile setAltSubmit={setAltSubmit} submitDom={submitDom} types={true} /> : <AlterWindow setAltSubmit={setAltSubmit} submitDom={submitDom} bgImg={bgFrom} />}
    </>
}

export default OfficialContact;