import { memo } from "react";
import "./index.less";

const Module1 = memo(() => {
  return (
    <div className="common-module-box about-us-module1">
      <div className="common-module-title">{data.title}</div>
      <div className="common-module-text">{data.text}</div>
    </div>
  );
});
export default Module1;
const data = {
  title: "公司介绍",
  text: `衔远科技成立于2021年，是全球领先的人工智能公司，前瞻性提出「通专融合」的技术理念，
  致力于在大模型通用性能的基础上，在特定领域达到专家级水平，推动 Expert AGI 的实现及其落地。
  目前，已自研首个具备「通专融合」新架构的大语言模型 ProductGPT 和多模态大模型 ProductDiffusion`,
};
