import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BASE_ROUTE_NAME } from "../../../config/index";
import { scrollTopSmooth } from "../../../utils/scroll";
import codeChart from "../../images/codeChart.png";
import { CommonContext } from "../../../common/commonContext";
import { debounce } from "../../../utils/utils";

const SiderMenu: FC = () => {
  const history = useHistory();
  const [floatingActive, setFloatingActive] = useState(-1);
  const { setShowService } = useContext(CommonContext);
  const [showTop, setShowTop] = useState(false);

  const { pathname } = useLocation();
  const onScroll = debounce(() => {
    setShowTop(window.scrollY > window.innerHeight);
  }, 20);
  useEffect(() => {
    onScroll();
    document.addEventListener("scroll", onScroll, false);
    return () => {
      document.removeEventListener("scroll", onScroll, false);
    };
  }, [pathname]);

  const floatingList = [
    {
      icon: "icon-headset",
      text: "客服",
    },
    {
      icon: "icon-wecode",
      text: "公众号",
    },
    {
      icon: "icon-handshake",
      text: "加入我们",
    },
  ];

  const floatingWindow = (ind: any) => {
    if (ind !== floatingList.length - 1) {
      setFloatingActive(ind);
    } else {
      scrollTopSmooth();
      setFloatingActive(-1);
    }
    if (ind === 2) {
      history.push(`${BASE_ROUTE_NAME}/about/join`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <div className="floating-window">
        <div className="operators">
          {floatingList.map((item, index) => {
            return (
              <div
                onClick={() => floatingWindow(index)}
                onMouseEnter={() => {
                  if (index === 0) setShowService?.("hover");
                  else {
                    index !== 3 && setFloatingActive(index);
                    setShowService?.("");
                  }
                }}
                onMouseLeave={() => index === 2 && setFloatingActive(-1)}
                key={index}
                className={`operator ${
                  floatingActive === index ? "floating-active" : ""
                }`}
              >
                <div className="iconfont-box">
                  <span className={`iconfont ${item.icon}`}></span>
                </div>
                <span className="text">{item.text}</span>
              </div>
            );
          })}
        </div>
        {showTop && (
          <div onClick={() => scrollTopSmooth()} className="go-top">
            <span className={`iconfont icon-arrow-up`}></span>
          </div>
        )}
      </div>

      {/* 关注公众号二维码 */}
      <dl
        className="code-chart"
        style={{ display: floatingActive === 1 ? "block" : "none" }}
        onMouseLeave={() => setFloatingActive(-1)}
      >
        <dt>- 官方公众号 -</dt>
        <dd style={{ backgroundImage: `url(${codeChart})` }}></dd>
      </dl>
    </>
  );
};

export default SiderMenu;
