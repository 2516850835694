import { memo } from "react";
import "./index.less";
import icon1 from "../../images/module2-icon1.svg";
import icon2 from "../../images/module2-icon2.svg";
import icon3 from "../../images/module2-icon3.svg";

const Module2 = memo(() => {
  return (
    <div className="common-module-box common-module-padding-bottom home-module2">
      <div className="common-module-title">{data.title}</div>
      <div className="common-module-text">{data.text}</div>
      <div className="common-module-row-list col3 gap4 cards home-module2-list">
        {data.list.map((child) => (
          <div className="col row" key={child.text}>
            <img className="col-top-icon" src={child.icon} alt={child.title} />
            <div className="col-title">{child.title}</div>
            <div className="col-text">{child.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
});
export default Module2;
const data = {
  title: "全球首个通专融合大模型",
  text: `衔远科技自研大语言模型 ProductGPT 与多模态模型 ProductDiffusion 已经通过中央网信办备案，是国内少有通过双模型备案的企业，
  衔远自研大模型立足商品领域，兼备“智商”、“情商”以及“品商”，拥有领先同参数规模模型的场景理解能力以及情绪理解能力，
  能够帮助品牌在商品营销、创意策划、产品创新、销售转化、知识管理、员工培训、数据分析等领域进行优化赋能`,
  list: [
    {
      icon: icon1,
      title: "智商突出",
      text: `从数十亿级端侧模型到千亿级大模型全覆盖，
      具备可靠的理解、推理、分析以及生成能力，
      拥有同规模 LLM 中领先的“智商”`,
    },
    {
      icon: icon2,
      title: "情商领先",
      text: `海量优质文案与文字语料，
      结合行业专家的标注与反馈，
      对中文语境理解更加准确，更懂“梗”，更懂消费者`,
    },
    {
      icon: icon3,
      title: "品商独创",
      text: `融入千万级商品参数与商品卖点数据，
      在保障模型质量的前提下，
      构建强大的商品与消费者理解能力`,
    },
  ],
};
