import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../context';
import { BASE_ROUTE_NAME } from '../config/index';

const useNavStyle = () => {
  const { setNavStyle } = useContext(AppContext);
  const { pathname } = useLocation();
  useEffect(() => {
    if (
      [`${BASE_ROUTE_NAME}`].includes(pathname)
    ) setNavStyle?.('white')
    else setNavStyle?.('black')
  }, [pathname, setNavStyle])
}
export default useNavStyle;