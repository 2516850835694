/**
 * 跨域图片实现点击下载
 * @param src
 */
export const download = (src: string, type?: string, name?: string) => {
  const arr = src.split("/");
  let fileName = `${arr[arr.length - 1]}`;
  const typeArray = type?.split("/");
  if (!fileName.includes("."))
    fileName = `${fileName}.${
      typeArray?.length ? typeArray[typeArray.length - 1] : "png"
    }`;

  urlToBase64(src).then((src) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous"); //解决跨域
    img.referrerPolicy = "no-referrer";
    img.src = src;
    img.name = fileName;
    const link = document.createElement("a");
    link.href = img.src;
    link.target = "_blank";
    link.download = name || img.name;
    link.click();
  });
};

export const urlToBase64 = async (url: string): Promise<string> => {
  if (!url || !url.startsWith("http")) return url;
  const res = await new Promise<File>((resolve) => {
    fetch(url.replace(/(http:)|(http:)/, window.location.protocol))
      .then((res) => {
        return res.blob();
      })
      .then((res) => {
        resolve(res as File);
      })
      .catch((error) => {
        console.error(error);
      });
  });
  const base64 = await fileToBase64(res);
  return base64 as string;
};

export function fileToBase64(file: File) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      resolve(e.target?.result);
    };
  });
}

/**
 * debounce
 */
export const debounce = (
  func: (...args: any[]) => any,
  wait: number,
  context?: any,
  immediate = false
): ((...args: any[]) => any) => {
  context = context || null;
  let timeout: NodeJS.Timeout | null;
  return (...args: any[]) => {
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.call(context, ...args);
      }
    };
    const callNow = immediate && !timeout;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.call(context, ...args);
    }
  };
};

export interface IImageInfo {
  src: string;
  width: number;
  height: number;
  img: HTMLImageElement;
}
export const getImageInfo = (src: string): Promise<IImageInfo> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve?.({
        src: src as string,
        width: img.naturalWidth,
        height: img.naturalHeight,
        img,
      });
    };
    img.src = src as string;
  });
};
/**
 * 腾讯云压缩图片
 * @returns
 */
export const imageZip = (src: string, size?: number) => {
  if (!src || !src?.startsWith("http")) return src;
  if (size !== undefined) {
    if (src?.includes("?"))
      return `${src}&imageView2/2/w/${
        size * window.devicePixelRatio
      }/format/webp|imageMogr2/strip`;
    return `${src}?imageView2/2/w/${
      size * window.devicePixelRatio
    }/format/webp|imageMogr2/strip`;
  }

  if (src?.includes("?")) return `${src}&imageMogr2/format/webp`;
  return `${src}?imageMogr2/format/webp`;
  // ?imageView2/2/w/60/format/webp|imageMogr2/strip
  // if (src?.includes('?')) return `${src}&imageView2/0/w/${size}/h/${size}/q/${q}`.replace(/https?:/, '');
  // return `${src}?imageView2/0/w/${size}/h/${size}/q/${q}`.replace(/https?:/, '');
};
