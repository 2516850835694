import { useSetHeader } from "../../hooks/useSetHeader";
import useViewport from "../../hooks/useViewport";
import ProductIntroductionMobile from "./mobile";
import ProductIntroductionPc from "./pc";

const ProductIntroduction = () => {
  const { isMobile } = useViewport();
  useSetHeader({
    title: "产品参谋，首个AI驱动的CIP协同创新平台",
    keywords: "产品参谋,CIP,AI协同创新平台,新品创新中心,产品企划,市场洞察",
    description:
      "产品参谋是一款为产品企划从业者及企业，提供市场洞察、产品定位、产品概念设计等产品创新服务的生产力工具与数智化协同平台。",
  });
  return isMobile ? <ProductIntroductionMobile /> : <ProductIntroductionPc />;
};
export default ProductIntroduction;
