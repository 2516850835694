import { memo, useState } from 'react';
import ApplyBtn from '../../../../components/applyBtn';
import { lights } from './const';
import useViewport from '../../../../hooks/useViewport';
import "./media.less";
import "./index.less";

const ProductHighLights = memo(() => {
  const { isMobile } = useViewport();
  const [active, setActive] = useState(0);

  return (
    <div className={`product-highlights ${isMobile ? 'product-highlights-mobile' : ''}`}>
      <h2>产品功能亮点</h2>
      <div className='lights-wrapper'>
        <div className='lights-box'>
          {lights.map((item, index) => {
            return (
              <div
                className={`light-item${active === index ? ' active' : ''}`}
                key={item.title}
                onClick={() => setActive(index)}
              >
                <img src={item.icon} alt="" />
                {item.title}
              </div>
            )
          })}
        </div>
        <div className='rect'></div>
      </div>
      <div className='light-content'>
        <div className='left'>
          <div className='title'>{lights[active].title}</div>
          <div className='texts-box'>
            {lights[active].texts.map(item => {
              return (
                <div className='text' key={item}>
                  <span className='iconfont icon-check'></span>
                  {item}
                </div>
              )
            })}
          </div>
          <ApplyBtn />
        </div>
        <img src={lights[active].img} alt='' className='right' />
      </div>
    </div>
  )
})

export default ProductHighLights;