import { FC, Fragment } from "react";
import { useHistory } from "react-router-dom";
import routes from "../../../router";
import logo from "../../../images/logo-white.svg";
import "./media.less";

const MenuMobile: FC<any> = ({ setShowMenuMobile }) => {
  const history = useHistory();
  return (
    <div className="menu-mobile-fixed">
      <div className="header">
        <img className="logo" src={logo} alt="" />
        <span
          className="iconfont icon-close"
          onClick={() => setShowMenuMobile(false)}
        ></span>
      </div>
      {routes.map((route) => {
        return (
          <Fragment key={route.path}>
            {route.name && !route.disabled && (
              <div
                onClick={() => {
                  history.push(route.redirect || route.path);
                  window.scrollTo({ top: 0 });
                  setShowMenuMobile(false);
                }}
                className={`route ${route.parentPath ? "route-theme" : ""}`}
              >
                {route.name}
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default MenuMobile;
