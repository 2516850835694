import { useRef, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { REACT_APP_REQUEST_URL } from "../../../env";
import { BASE_ROUTE_NAME } from "../../../config";
import useViewport from "../../../hooks/useViewport";
import customerService from "../images/customerService.png";
import { getQueryString } from "../../../config/index";

import "./index.less";
import "./media.less";

interface IProps {
  headerText: any;
  titleHeight?: any;
}

interface IForm {
  phone: string;
  verifyCode: string;
  companyName: string;
  email: string;
}

const GroundForm = ({ headerText, titleHeight }: IProps) => {
  let times = useRef<NodeJS.Timeout>();
  let altMessageTime = useRef<NodeJS.Timeout>();
  const { isMobile } = useViewport();
  const history = useHistory();
  // const validatePhone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  // const [timers, setTimers] = useState(60); //时间
  // const [timeFlag, setTimeFlag] = useState(false); //是否开启倒计时
  const [messageFlag, setMessageFlag] = useState(false); //message提示
  const [messageText, setMessageText] = useState("未填写相关内容，请输入"); //输入错误提示文本
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formInfo, setFormInfo] = useState<IForm>({
    phone: "",
    verifyCode: "",
    companyName: "",
    email: "",
  });

  const onSubmit = () => {
    if (formInfo.phone.trim() !== "" && formInfo.companyName.trim() !== "") {
      let obj = {};
      const content = getQueryString("utm_content");
      const campaign = getQueryString("utm_medium");
      const source = getQueryString("utm_source");
      if (content) {
        obj = Object.assign({}, obj, { content: decodeURIComponent(content) });
      }
      if (campaign) {
        obj = Object.assign({}, obj, {
          campaign: decodeURIComponent(campaign),
        });
      }
      if (source) {
        obj = Object.assign({}, obj, {
          source: decodeURIComponent(source),
        });
      }
      axios
        .post(
          `${REACT_APP_REQUEST_URL}/customerinfo/withoutVerifyPhoneNumber`,
          Object.assign({}, formInfo, obj)
        )
        .then((res) => {
          if (res.data.code === 0) {
            setSubmitSuccess(true);
            setFormInfo({
              phone: "",
              companyName: "",
              verifyCode: "",
              email: "",
            });
            countDown(0);
          } else if (res.data.code === 0) {
            alterMessage(res.data.msg);
          } else {
            alterMessage(res.data.msg);
          }
        });
    } else {
      alterMessage("未填写相关内容，请输入");
    }
  };
  const alterMessage = (text: string) => {
    setMessageText(text);
    setMessageFlag(true);
    altMessageTime.current = setTimeout(() => {
      setMessageFlag(false);
      clearTimeout(times.current);
    }, 2000);
  };
  const countDown = (num: any) => {
    times.current = setTimeout(() => countDown(num), 1000);
    // setTimers((num -= 1));
    // if (num <= 0) {
    //     setTimers(60);
    //     setTimeFlag(false);
    //     clearTimeout(times.current);
    // }
  };

  // const getCode = () => {
  //     if (validatePhone.test(formInfo.phone)) {
  //         if (!timeFlag) {
  //             axios({
  //                 method: "get",
  //                 url: `${REACT_APP_REQUEST_URL}/frontis-privilege-manage/code/portalphonesend?phone=${formInfo.phone}`,
  //             }).then((res) => {
  //                 if (res.data.code === 0) {
  //                     countDown(timers);
  //                     setTimeFlag(true);
  //                 } else {
  //                     alterMessage(res.data.msg);
  //                 }
  //             });
  //         }
  //     } else {
  //         alterMessage('手机号错误，请重新输入');
  //     }
  // };
  return (
    <div className={isMobile ? "ground-form-mobile" : "ground-form"}>
      <div className="ground-form-content">
        <div className="ground-form-header" style={titleHeight}>
          <div className="form-header-size">{headerText}</div>
          {!submitSuccess && <img src={customerService} alt="" />}
          {messageFlag && (
            <div className="ground-from-message">
              <i></i>
              <p>{messageText}</p>
            </div>
          )}
        </div>
        <ul className="ground-form-list">
          <li>
            <input
              type="text"
              value={formInfo.phone}
              onChange={(e) =>
                setFormInfo(() => {
                  return { ...formInfo, phone: e.target.value };
                })
              }
              placeholder="*请输入您的手机号"
            />
          </li>
          {/* <li className='ground-get-code'>
                    <input type="text" value={formInfo.verifyCode}
                        onChange={(e) =>
                            setFormInfo(() => {
                                return { ...formInfo, verifyCode: e.target.value };
                            })
                        }
                        placeholder='*输入验证码' />
                    <div onClick={getCode}>{timeFlag ? `${timers}s` : '获取验证码'}</div>
                </li> */}
          <li>
            <input
              type="text"
              value={formInfo.companyName}
              onChange={(e) =>
                setFormInfo(() => {
                  return { ...formInfo, companyName: e.target.value };
                })
              }
              placeholder="*请输入您的公司名称"
            />
          </li>
          <li>
            <input
              type="text"
              value={formInfo.email}
              onChange={(e) =>
                setFormInfo(() => {
                  return { ...formInfo, email: e.target.value };
                })
              }
              placeholder="请填写您的公司邮箱"
            />
            {/* （非必填） */}
          </li>
          <li className="ground-form-submit">
            <button onClick={onSubmit}>提交</button>
          </li>
        </ul>

        {submitSuccess && (
          <div className="ground-from-success">
            <div className="ground-from-padding">
              <div className="ground-success-content">
                <i></i>
                <div>提交成功</div>
                <span>我们将于2个工作日内与您取得联系</span>
                <p
                  onClick={() =>
                    history.push(
                      BASE_ROUTE_NAME.trim() !== ""
                        ? BASE_ROUTE_NAME
                        : `/${BASE_ROUTE_NAME}`
                    )
                  }
                >
                  点击进入衔远官网
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroundForm;
