import { memo } from "react";
import { ScenePc } from "./pc";
import { all_data, expert_data, private_data } from "./data";

export const AllScene = memo(() => {
  return <ScenePc data={all_data} />;
});
export const ExpertScene = memo(() => {
  return <ScenePc data={expert_data} />;
});
export const PrivateScene = memo(() => {
  return <ScenePc data={private_data} />;
});
