import { memo } from "react";
import num3 from "../../images/03.svg";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";

const Module2 = memo(() => {
  return (
    <div className="fr-science-pc-module2">
      <div className="content">
        <img className="num" src={num3} alt={data.title} />
        <div className="title">{data.title}</div>
        <div className="bot">
          {data.list.map((child) => (
            <div key={child.title} className="bot-item">
              <img
                className="pic"
                src={imageZip(`${CDN_PREFIX}${child.img}`)}
                alt={child.title}
              />
              <div className="bot-title">{child.title}</div>
              <div className="bot-text">{child.text}</div>
              <ol>
                {child.ol.map((li, index) => (
                  <li key={index}>
                    <span>·</span>
                    <span>{li}</span>
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
export default Module2;
const data = {
  title: "衔远通专融合AI for Science方案概述",
  list: [
    {
      title: "基础模型的通专融合能力提升",
      text: "解决高度专业化缺失问题",
      ol: [
        "提升大模型在科学发现方面的专业能力，达到指定学科下的专家级水平",
        "保持大模型在通用泛化性，如跨任务处理能力、高阶复杂问题逻辑推理能力的领先性",
        "可持续更新的进阶知识聚合体",
      ],
      img: "static/frontisportal/science/case1.png",
    },
    {
      title: "面向知识发现和升阶的AI系统",
      text: "解决知识发现跃迁难问题",
      ol: [
        "搭建面向知识发现和升阶的分析、假设提出和验证系统",
        "实现确定推理、知识泛化和科学计算的深度融合",
        "从单点知识发现进行归纳升阶，直到元知识发现",
      ],
      img: "static/frontisportal/science/case2.png",
    },
    {
      title: "动力学知识多维度验证体系",
      text: "解决知识验证效率低问题",
      ol: [
        "利用知识聚合体进行自动化、小规模验证和证据获取，增强知识发现的置信度",
        "搭建基于动力学的知识验证体系",
        "干实验验证与湿实验部分进行充分深度结合",
      ],
      img: "static/frontisportal/science/case3.png",
    },
  ],
};
