import axios from "axios";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useState, useRef } from "react";
import { REACT_APP_REQUEST_URL } from "../../env";
import { BASE_ROUTE_NAME } from "../../config";
import { useSetHeader } from "../../hooks/useSetHeader";
import "./media.less";

const Contact = () => {
  let times = useRef<NodeJS.Timeout>();
  const history = useHistory();
  useSetHeader({});
  const validatePhone =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  const validateEmails = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  const [nameFlag, setNameFlag] = useState(true); //姓名校验
  const [phoneFlag, setPhoneFlag] = useState(true); //手机号校验
  const [codelag, setCodeFlag] = useState(true); //验证码校验
  const [companyNameFlag, setCompanyNameFlag] = useState(true); //公司名称校验
  const [emailsFlag, setEmailsFlag] = useState(true); //邮箱校验
  const [timers, setTimers] = useState(60); //时间
  const [timeFlag, setTimeFlag] = useState(false); //是否开启倒计时
  const [formInfo, setFormInfo] = useState<any>({
    name: "",
    phone: "",
    companyName: "",
    verifyCode: "",
    email: "",
    description: "",
  });

  const countDown = (num: any) => {
    times.current = setTimeout(() => countDown(num), 1000);
    setTimers((num -= 1));
    if (num <= 0) {
      setTimers(60);
      setTimeFlag(false);
      clearTimeout(times.current);
    }
  };

  const onSubmit = () => {
    if (
      phoneFlag &&
      codelag &&
      companyNameFlag &&
      emailsFlag &&
      formInfo.phone.trim() !== "" &&
      formInfo.companyName.trim() !== "" &&
      formInfo.verifyCode.trim() !== "" &&
      formInfo.email.trim() !== ""
    ) {
      axios
        .post(`${REACT_APP_REQUEST_URL}/customerinfo`, formInfo)
        .then((res) => {
          if (res.data.code === 0) {
            toast.success("提交成功");
            setFormInfo({
              name: "",
              phone: "",
              companyName: "",
              verifyCode: "",
              email: "",
              description: "",
            });
            countDown(0);
          } else if (res.data.code === 0) {
            toast.error(res.data.msg);
          } else {
            toast.error(res.data.msg);
          }
        });
    } else {
      let newObj = JSON.parse(JSON.stringify(formInfo));
      for (let key in newObj) {
        if (newObj[key].trim() === "" && key === "phone") {
          setPhoneFlag(false);
        } else if (newObj[key].trim() === "" && key === "companyName") {
          setCompanyNameFlag(false);
        } else if (newObj[key].trim() === "" && key === "verifyCode") {
          setCodeFlag(false);
        } else if (newObj[key].trim() === "" && key === "email") {
          setEmailsFlag(false);
        }
      }
    }
  };

  const getCode = () => {
    if (validatePhone.test(formInfo.phone)) {
      axios({
        method: "get",
        url: `${REACT_APP_REQUEST_URL}/frontis-privilege-manage/code/portalphonesend?phone=${formInfo.phone}`,
      }).then((res) => {
        if (res.data.code === 0) {
          toast.success("验证码获取成功");
          countDown(timers);
          setTimeFlag(true);
        } else {
          toast.error(res.data.msg);
        }
      });
    } else {
      setPhoneFlag(false);
    }
  };

  const validForm = () => {
    let arr = [];
    for (var i in formInfo) {
      if (!formInfo[i]) {
        arr.push(i);
      }
    }
    return arr.length ? false : true;
  };

  return (
    <div className="center-content">
      <dd className="contact-content">
        <div
          className="contact-logo"
          onClick={() =>
            history.push(
              BASE_ROUTE_NAME.trim() !== ""
                ? BASE_ROUTE_NAME
                : `/${BASE_ROUTE_NAME}`
            )
          }
        ></div>
        <h1>
          免费诊断
          <br />
          获得更多专业咨询
        </h1>
        <ul className="contact-form">
          <li>
            <input
              type="text"
              onChange={(e) =>
                setFormInfo(() => {
                  setNameFlag(e.target.value.trim() !== "");
                  return { ...formInfo, name: e.target.value };
                })
              }
              placeholder="请输入您的姓名"
              value={formInfo.name}
            />
          </li>
          <li style={{ visibility: nameFlag ? "hidden" : "inherit" }}>
            正确填写姓名，我们的销售才能更好的为您提供服务
          </li>
          <li>
            <input
              type="text"
              onChange={(e) =>
                setFormInfo(() => {
                  setPhoneFlag(
                    e.target.value.trim() !== "" &&
                      validatePhone.test(e.target.value)
                  );
                  return { ...formInfo, phone: e.target.value };
                })
              }
              placeholder="请输入您的手机号"
              value={formInfo.phone}
            />
          </li>
          <li style={{ visibility: phoneFlag ? "hidden" : "inherit" }}>
            正确填写手机号，我们的销售才能更好的为您提供服务
          </li>
          <li className="get-codes">
            <input
              type="text"
              onChange={(e) =>
                setFormInfo(() => {
                  setCodeFlag(e.target.value.trim() !== "");
                  return { ...formInfo, verifyCode: e.target.value };
                })
              }
              placeholder="输入验证码"
              value={formInfo.verifyCode}
            />
            <span
              onClick={getCode}
              style={{ color: timeFlag ? "#ccc" : "#2352D8" }}
            >
              {timeFlag ? `${timers}后重新获取` : "获取验证码"}
            </span>
          </li>
          <li style={{ visibility: codelag ? "hidden" : "inherit" }}>
            正确填写验证码，我们的销售才能更好的为您提供服务
          </li>
          <li>
            <input
              type="text"
              onChange={(e) =>
                setFormInfo(() => {
                  setCompanyNameFlag(e.target.value.trim() !== "");
                  return { ...formInfo, companyName: e.target.value };
                })
              }
              placeholder="请输入您的公司名称"
              value={formInfo.companyName}
            />
          </li>
          <li style={{ visibility: companyNameFlag ? "hidden" : "inherit" }}>
            正确填写公司名称，我们的销售才能更好的为您提供服务
          </li>
          <li>
            <input
              type="text"
              onChange={(e) =>
                setFormInfo(() => {
                  setEmailsFlag(
                    e.target.value.trim() !== "" &&
                      validateEmails.test(e.target.value)
                  );
                  return { ...formInfo, email: e.target.value };
                })
              }
              placeholder="请输入您的邮箱"
              value={formInfo.email}
            />
          </li>
          <li style={{ visibility: emailsFlag ? "hidden" : "inherit" }}>
            正确填写邮箱，我们的销售才能更好的为您提供服务
          </li>
          <li className="contact-textarea">
            <textarea
              onChange={(e) =>
                setFormInfo(() => {
                  if (e.target.value.split("").length < 101) {
                    return { ...formInfo, description: e.target.value };
                  } else {
                    return { ...formInfo };
                  }
                })
              }
              placeholder="请输入咨询产品或解决方案"
              value={formInfo.description}
            />
            <span>{formInfo.description.length || 0}/100</span>
          </li>
        </ul>
        <button
          style={{
            background: `${validForm() ? "#2352D8" : "rgba(28, 41, 71, 0.45)"}`,
          }}
          onClick={onSubmit}
        >
          提交
        </button>
      </dd>
      <Toaster />
    </div>
  );
};

export default Contact;
