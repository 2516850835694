import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Layout from "./layout";
import AppContextProvider from "./context";
import "./index.less";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppContextProvider>
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  </AppContextProvider>
);
reportWebVitals();
