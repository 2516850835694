import { memo, useEffect, useState } from "react";
import arrow from "../images/arrow.svg";
import "./index.less";
interface IProps {
  options?: Array<string>;
  value?: string;
  onChange?: (v: string) => void;
}
const FrSelect = memo<IProps>(({ options, value, onChange }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {}, []);
  return (
    <div className="custom-select-element">
      <input
        onClick={() => setShow(!show)}
        onBlur={() => {
          setTimeout(() => {
            setShow(false);
          }, 200);
        }}
      />
      <div className={`select-selected${!value ? " placeholder" : ""}`}>
        {value ? value : "请选择行业"}
        <img src={arrow} className="arrow" alt="请选择行业" />
      </div>
      <div className={`select-items${show ? " show" : ""}`}>
        {options?.map((item) => (
          <div
            className={`select-item${value === item ? " active" : ""}`}
            data-value={item}
            key={item}
            onClick={() => onChange?.(item)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
});
export default FrSelect;
