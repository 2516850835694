// import Image from '../../../../components/Image';
import useViewport from '../../../../hooks/useViewport';
import img from './images/img.jpg';
import './media.less';
import './index.less';

const Innovate = () => {
  const { isMobile } = useViewport();
  return (
    <div className={`innovate ${isMobile ? 'innovate-mobile' : ''}`}>
      <div className='innovate-content'>
        <h2>全面提升企业AI大模型快速落地<br />帮助企业实现从数字化到智能化的快速转化</h2>
        <p>基于人工智能领域多年的技术深耕与积累，结合丰富的企业级客户及专业服务经验，衔远科技打造ProductGPT——给企业每一个员工使用的多轮对话平台， 帮助提升不仅限于下面的生产力</p>
        {/* <Image src={img} alt="" className='innovate-img' /> */}
        <img src={img} alt="" className='innovate-img' />
      </div>
    </div>
  )
}

export default Innovate