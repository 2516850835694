import { memo } from "react";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import originator from "../../images/originator.svg";

const Module2 = memo(() => {
  return (
    <div className="common-module-full common-module-padding-top about-us-module2">
      <div className="common-module-title">{data.title}</div>
      <div className="common-module-full content">
        <div className="common-module-box content-md">
          <div className="common-module-row-list col2 gap16 image-list">
            {data.images.map((src) => (
              <img
                className="col"
                key={src}
                src={imageZip(`${CDN_PREFIX}${src}`)}
                alt=""
              />
            ))}
          </div>
          <div className="right">
            <div className="right-title">
              <img className="tag" src={originator} alt={data.right.title} />
              <span className="name">{data.right.title}</span>
            </div>
            <div className="right-text">{data.right.text}</div>
            <div className="right-subtitle">{data.right.subTitle}</div>
            <div className="common-module-row-list gap12 col5 right-tags">
              {data.right.tags.map((child) => (
                <span
                  key={child}
                  className="tag"
                  style={
                    child === "IBM"
                      ? {
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                        }
                      : {}
                  }
                >
                  {child}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Module2;
const data = {
  title: "衔远团队",
  images: [
    `static/frontisportal/introduce/introduce-team1.png`,
    `static/frontisportal/introduce/introduce-team2.png`,
    `static/frontisportal/introduce/introduce-team3.png`,
    `static/frontisportal/introduce/introduce-team4.png`,
  ],
  right: {
    title: "周伯文",
    titleTag: originator,
    text: `· 上海人工智能实验室主任、首席科学家 
    · 清华大学惠妍讲席教授、电子工程系长聘教授 
    · 曾任京东集团高级副总裁、集团技术委员会主席 
    · 曾任IBM Research人工智能基础研究院院长、IBM Watson集团首席科学家、IBM杰出工程师`,
    subTitle: "云集前沿技术专家与产研先行者，团队来自于",
    tags: ["京东", "小米", "腾讯", "百度", "字节", "阿里", "微软", "IBM"],
  },
};
