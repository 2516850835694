import { memo } from "react";
import "./index.less";
import { all_data } from "../../data";

const Module1 = memo<{ data: typeof all_data.items }>(({ data }) => {
  return (
    <div className="common-module-box  solution-scene-module1">
      {data.map((child) => (
        <div
          className="common-module-row-list gap5 col2 item"
          key={child.title + child.subTitle}
        >
          <div className="col text-block">
            <div className="col-title-bg">
              {child.title}
              <span>{child.subTitle}</span>
            </div>
            <ol
              className={`col-list ${
                child.list.length <= 1 ? "no-list-style" : ""
              }`}
            >
              {child.list.map((child, index) => (
                <li key={child}>
                  <span className="num">{`0${index + 1}`}</span>
                  <div className="text">{child}</div>
                </li>
              ))}
            </ol>
          </div>
          <img
            className="col pic"
            src={child.pic}
            alt={child.title + child.subTitle}
          />
        </div>
      ))}
    </div>
  );
});
export default Module1;
