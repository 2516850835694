import { memo, useEffect, useState } from "react";
import { getQueryString } from "../../../config";
import axios from "axios";
import { REACT_APP_REQUEST_URL } from "../../../env";
import toast from "react-hot-toast";
import checkedIcon from "../../../images/checked.svg";
import noCheckIcon from "../../../images/no-check.svg";
import "./index.less";
import "./mobile.less";
import serverCode from "../../images/serverCode.jpeg";
import FrSelect from "./components/frSelect";
import useViewport from "../../../hooks/useViewport";
const validatePhone =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
const validateEmails = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
const required = [
  "name",
  "phone",
  "companyName",
  "verifyCode",
  "email",
  "industry",
];
interface IServiceWindow {
  onClose?: () => void;
  show?: "click" | "hover" | "";
}
const ServiceWindow = memo<IServiceWindow>(({ show, onClose }) => {
  const [nameFlag, setNameFlag] = useState(true); //姓名校验
  const [phoneFlag, setPhoneFlag] = useState(true); //手机号校验
  const [companyNameFlag, setCompanyNameFlag] = useState(true); //公司名称校验
  const [emailsFlag, setEmailsFlag] = useState(true); //邮箱校验
  const [industryFlag, setIndustryFlag] = useState(true); //邮箱校验
  const [loading, setLoading] = useState(false);
  const [formInfo, setFormInfo] = useState<any>({
    name: "",
    phone: "",
    companyName: "",
    verifyCode: "",
    email: "",
    description: "",
    industry: "",
  });

  const { isMobile } = useViewport();
  const onSubmit = () => {
    if (
      phoneFlag &&
      companyNameFlag &&
      emailsFlag &&
      industryFlag &&
      formInfo.phone.trim() !== "" &&
      formInfo.companyName.trim() !== "" &&
      formInfo.email.trim() !== "" &&
      agree
    ) {
      let obj = {};
      const content = getQueryString("utm_content");
      const campaign = getQueryString("utm_medium");
      const source = getQueryString("utm_source");
      if (content) {
        obj = Object.assign({}, obj, {
          content: decodeURIComponent(content),
        });
      }
      if (campaign) {
        obj = Object.assign({}, obj, {
          campaign: decodeURIComponent(campaign),
        });
      }
      if (source) {
        obj = Object.assign({}, obj, {
          source: decodeURIComponent(source),
        });
      }
      setLoading(true);
      axios
        .post(
          `${REACT_APP_REQUEST_URL}/customerinfo/withoutVerifyPhoneNumber`,
          Object.assign({}, formInfo, obj)
        )
        .then((res) => {
          setLoading(false);
          if (res.data.code === 0) {
            toast.success("提交成功");
            setFormInfo({
              name: "",
              phone: "",
              companyName: "",
              verifyCode: "",
              email: "",
              description: "",
              industry: "",
            });
            // setAlterFlag(false);
            // //提交成功的遮罩层开关
            // setAltSubmit(true);
            onClose?.();
          } else if (res.data.code === 0) {
            toast.error(res.data.msg);
          } else {
            toast.error(res.data.msg);
          }
        });
    } else {
      let newObj = JSON.parse(JSON.stringify(formInfo));
      for (let key in newObj) {
        if (newObj[key].trim() === "" && key === "phone") {
          setPhoneFlag(false);
        } else if (newObj[key].trim() === "" && key === "companyName") {
          setCompanyNameFlag(false);
        } else if (newObj[key].trim() === "" && key === "verifyCode") {
          // setCodeFlag(false);
        } else if (newObj[key].trim() === "" && key === "industry") {
          setIndustryFlag(false);
        } else if (newObj[key].trim() === "" && key === "email") {
          setEmailsFlag(false);
        }
      }
    }
  };

  const [agree, setAgree] = useState(true);

  const validForm = () => {
    let arr = [];

    for (const i of required) {
      if (!formInfo[i] && i !== "verifyCode") {
        arr.push(i);
      }
    }

    return arr.length || !agree ? false : true;
  };
  useEffect(() => {
    if (show && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show, isMobile]);
  return (
    <>
      <div
        style={{ display: show ? "block" : "none" }}
        className={`fr-service-window${
          isMobile ? " mobile-service-window" : ""
        }`}
      >
        <div className="fr-service-window-content">
          <div className="service-window-title">咨询与合作</div>
          <span className="iconfont icon-close close-icon" onClick={onClose} />
          <div className="service-window-text">
            请完善信息，让我们为您更好的服务
          </div>
          <ul className="alter-form">
            <li>
              <input
                type="text"
                onChange={(e) =>
                  setFormInfo(() => {
                    setNameFlag(e.target.value.trim() !== "");
                    return { ...formInfo, name: e.target.value };
                  })
                }
                placeholder="请填写真实姓名"
                value={formInfo.name}
              />
            </li>
            <li
              style={{ visibility: nameFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              <span>正确填写姓名，我们的销售才能更好的为您提供服务</span>
            </li>
            <li>
              <input
                type="text"
                onChange={(e) =>
                  setFormInfo(() => {
                    return { ...formInfo, phone: e.target.value };
                  })
                }
                onBlur={(e) => {
                  setPhoneFlag(
                    e.target.value.trim() !== "" &&
                      validatePhone.test(e.target.value)
                  );
                }}
                placeholder="请输入您的手机号"
                value={formInfo.phone}
              />
            </li>
            <li
              style={{ visibility: phoneFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              <span>正确填写手机号，我们的销售才能更好的为您提供服务</span>
            </li>
            <li>
              <input
                type="text"
                onChange={(e) =>
                  setFormInfo(() => {
                    setCompanyNameFlag(e.target.value.trim() !== "");
                    return { ...formInfo, companyName: e.target.value };
                  })
                }
                placeholder="请填写真实的公司名称"
                value={formInfo.companyName}
              />
            </li>
            <li
              style={{ visibility: companyNameFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              <span>正确填写公司名称，我们的销售才能更好的为您提供服务</span>
            </li>
            {/* <li>
          <input
            type="text"
            onChange={(e) =>
              setFormInfo(() => {
                setAddressFlag(e.target.value.trim() !== "");
                return { ...formInfo, address: e.target.value };
              })
            }
            placeholder="请输入您的公司地址"
            value={formInfo.address}
          />
        </li> */}
            {/* <li
          style={{ visibility: addressFlag ? "hidden" : "inherit" }}
          className="i-text"
        >
          正确填写公司地址，我们的销售才能更好的为您提供服务
        </li> */}
            <li>
              <input
                type="text"
                onChange={(e) =>
                  setFormInfo(() => {
                    setEmailsFlag(
                      e.target.value.trim() !== "" &&
                        validateEmails.test(e.target.value)
                    );
                    return { ...formInfo, email: e.target.value };
                  })
                }
                placeholder="请输入您的邮箱"
                value={formInfo.email}
              />
            </li>
            <li
              style={{ visibility: emailsFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              <span>正确填写邮箱，我们的销售才能更好的为您提供服务</span>
            </li>
            <li>
              <FrSelect
                onChange={(v) => {
                  setFormInfo(() => {
                    setIndustryFlag(!!v);
                    return { ...formInfo, industry: v };
                  });
                }}
                value={formInfo.industry}
                options={[
                  "食品饮料",
                  "时尚鞋服",
                  "美妆个护",
                  "金融保险",
                  "其他",
                ]}
              />
            </li>
            <li
              style={{ visibility: industryFlag ? "hidden" : "inherit" }}
              className="i-text"
            >
              <span>请选择行业，我们的销售才能更好的为您提供服务</span>
            </li>
            <li className="contact-textarea">
              <div className="textarea-box">
                <textarea
                  onChange={(e) =>
                    setFormInfo(() => {
                      if (e.target.value.length <= 100) {
                        return { ...formInfo, description: e.target.value };
                      } else {
                        return { ...formInfo };
                      }
                    })
                  }
                  placeholder="请输入应用场景"
                  value={formInfo.description}
                />
                <div className="border-box" />
                <span>{formInfo.description.length || 0}/100</span>
              </div>
            </li>
          </ul>
          <div className="agreement-box">
            <div className="my-checkbox">
              <input
                type="checkbox"
                onChange={(e) => {
                  setAgree(e.target.checked);
                }}
                checked={agree}
              />
              <div className="my-checkbox-inner">
                <img src={agree ? checkedIcon : noCheckIcon} alt="" />
              </div>
            </div>
            点击提交即代表我已同意
            <span
              className="read-span"
              onClick={() => {
                window.open(
                  "https://xianyuan.feishu.cn/docx/AmW2dxQWzoic9UxlsjgcudWqnbg"
                );
                // window.open("/platform-sso/user-agreement?file=userAgreement");
              }}
            >
              《用户协议》
            </span>
            <span
              className="read-span"
              onClick={() => {
                window.open(
                  "https://xianyuan.feishu.cn/docx/FQtIdOD8GoKHFXxZ3SbcO2RrnId"
                );
                // window.open(
                //   location.origin +
                //     "/platform-sso/user-agreement?file=privacyPolicy"
                // );
              }}
            >
              《隐私政策》
            </span>
          </div>
          <div className="submit-btn">
            <button
              disabled={loading}
              onClick={() => !loading && onSubmit()}
              className={validForm() ? "" : "disabled"}
            >
              提交
            </button>
          </div>
          <div className="fr-service-customer">
            <img className="bot-qrcode" src={serverCode} alt="" />
            <div className="mobile">扫码添加官方客服或拨打电话010-87531591</div>
          </div>
        </div>
      </div>
      <div
        style={{ display: !!show ? "block" : "none" }}
        className="fr-service-window-mask"
        onMouseEnter={() => show === "hover" && onClose?.()}
        onClick={() => onClose?.()}
      />
    </>
  );
});
export default ServiceWindow;
