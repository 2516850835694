import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BASE_ROUTE_NAME } from "../../config/index";

const Resource = () => {
  const history = useHistory();
  useEffect(() => history.push(`${BASE_ROUTE_NAME}/news`))
  return (
    <div></div>
  )
}
export default Resource;