import { memo } from "react";
import "./index.less";
import icon1 from "../images/icon1.svg";
import icon2 from "../images/icon2.svg";
import icon3 from "../images/icon3.svg";

const Module1 = memo(() => {
  return (
    <div className="common-module-box maas-module1">
      <div className="common-module-title">{data.title}</div>
      <div className="common-module-row-list gap3 col3 module1-list">
        {data.items.map((child) => (
          <div className="col item" key={child.text}>
            <img
              className="col-top-left-icon item-icon"
              src={child.icon}
              alt={child.title}
            />
            <div className="col-title left">{child.title}</div>
            <div className="col-border-box">{child.sub}</div>
            <div className="col-text left item-text">{child.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
});
export default Module1;
const data = {
  title: `大模型行业化落地的核心挑战`,
  items: [
    {
      icon: icon1,
      title: "基础大模型缺乏行业专业性",
      sub: "大模型处理特定领域问题或任务需要有高准确性与效率",
      text: "大模型专业性要求越高，越需要针对特定领域数据进行训练，这可能造成模型过拟合而降低泛化能力。此外，增加的数据收集和训练也会增加成本、降低经济性",
    },
    {
      icon: icon2,
      title: "专业大模型需要有高泛化性",
      sub: "需要对训练数据集之外的全新样本有高准确性的优质表现",
      text: "在大模型处理训练数据集之外新样本的表现能力方面，大模型泛化性要求越高，需要多样化的大规模训练数据集、模型参数量也越多。这意味着模型训练和使用成本增加、经济性降低，同时可能降低模型对特定问题处理的专业能力",
    },
    {
      icon: icon3,
      title: "大模型建设需要具备经济性",
      sub: "大模型专业性的训练和应用的投入产出比要具备高性价比",
      text: "大模型经济性要求越高，越需要消耗更少的算力资源和成本满足性能需求，然而降低资源消耗基本上需要采用更小的模型或更少的参数，这又会降低模型的性能表现",
    },
  ],
};
