import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BASE_ROUTE_NAME } from "../../../config/index";
import { scrollTopSmooth } from "../../../utils/scroll";
import handleClipboard from "../../../utils/clipboard";
import ercode from "../../images/customerService.jpeg";
import iconCopy from "../../../images/icon-copy.png";
import "./media.less";
import { CommonContext } from "../../../common/commonContext";
import { debounce } from "../../../utils/utils";

const SiderMenuMobile: FC<any> = () => {
  const history = useHistory();
  const [showContact, setShowContact] = useState<boolean>(false);
  const [showAttention, setShowAttention] = useState<boolean>(false);
  const [showTop, setShowTop] = useState(false);
  const { pathname } = useLocation();
  const onScroll = debounce(() => {
    setShowTop(window.scrollY > window.innerHeight);
  }, 20);
  useEffect(() => {
    onScroll();
    document.addEventListener("scroll", onScroll, false);
    return () => {
      document.removeEventListener("scroll", onScroll, false);
    };
  }, [pathname]);

  return (
    <div className="sider-menu-mobile">
      <ul className="floating-window-mobile">
        <div className="floating-item-box">
          <li onClick={() => setShowContact(true)} className="floating-item">
            <span className="iconfont icon-headset"></span>
          </li>
          <li onClick={() => setShowAttention(true)} className="floating-item">
            <span className="iconfont icon-wecode"></span>
          </li>
          <li
            onClick={() => {
              history.push(`${BASE_ROUTE_NAME}/about/join`);
              scrollTopSmooth();
            }}
            className="floating-item"
          >
            <span className="iconfont icon-handshake"></span>
          </li>
        </div>
        {showTop && (
          <li
            onClickCapture={() => scrollTopSmooth()}
            className="floating-item floating-item-last"
          >
            <span className="iconfont icon-arrow-up"></span>
          </li>
        )}
      </ul>

      {showContact && (
        <ContactCsMobile setShowContact={(v: boolean) => setShowContact(v)} />
      )}
      {showAttention && <AttentionModal setShowAttention={setShowAttention} />}
    </div>
  );
};

const ContactCsMobile: FC<any> = ({ setShowContact }) => {
  const { setShowService, showService } = useContext(CommonContext);
  return (
    <div className="contact-us-mobile-modal">
      <div className="contact-us-mobile">
        <div className="item header">
          <div className="header-title">咨询客服</div>
          <span
            className="iconfont icon-close"
            onClick={() => setShowContact(false)}
          ></span>
        </div>
        <div className="item phone">
          <div>电话</div>
          <div className="right-text flex">
            010-87531591
            <img
              src={iconCopy}
              alt=""
              className="copy"
              onClick={(e) => handleClipboard("010-87531591", e)}
            />
          </div>
        </div>
        <div className="item code">
          <div className="left">官方客服</div>
          <img className="right-img" src={ercode} alt="" />
        </div>
        <div className="item contact">
          <div
            className="o-modal-btn"
            onClick={() => setShowService?.(showService ? "" : "click")}
          >
            合作咨询
          </div>
        </div>
      </div>
    </div>
  );
};

const AttentionModal: FC<any> = ({ setShowAttention }) => {
  return (
    <div className="attention-modal-mobile">
      <div className="attention-content">
        <div className="attention-content-header">
          <div className="left">微信公众号</div>
          <span
            className="iconfont icon-close"
            onClick={() => setShowAttention(false)}
          ></span>
        </div>
        <img
          className="code-img"
          src="https://cdn.frontis.top/cdn-content/static/frontisportal/kefu-code.png"
          alt=""
        />
        <p>长按保存或识别二维码</p>
      </div>
    </div>
  );
};

export default SiderMenuMobile;
