import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { Toaster } from "react-hot-toast";
import Routes from "../router";
import { BASE_ROUTE_NAME } from "../config/index";
import Header from "./components/header";
import Footer from "./components/footer";
import AlterWindow from "./components/alterWindow";
import MenuMobile from "./components/menuMobile";
import SiderMenu from "./components/siderMenu";
import SiderMenuMobile from "./components/siderMenuMobile";
import ContactUsMobile from "./components/contactMobile";
import useViewport from "../hooks/useViewport";
import useNavStyle from "../hooks/useNavStyle";
import "./index.less";
import "./media.less";
import { CommonContextProvider } from "../common/commonContext";
import HeaderPc from "./components/headerPc";

const Layout = () => {
  const { isMobile } = useViewport();
  const [showContactUsMobile, setShowContactUsMobile] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);
  const [alterFlag, setAlterFlag] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useNavStyle();
  if (
    [
      `${BASE_ROUTE_NAME}/contact`,
      `${BASE_ROUTE_NAME}/officialContact`,
      `${BASE_ROUTE_NAME}/groundPage`,
      `${BASE_ROUTE_NAME}/home`,
    ].includes(pathname)
  )
    return <>{renderRoutes(Routes)}</>;
  return (
    <>
      <CommonContextProvider>
        <div className={`${isMobile ? "layout-app-mobile" : "layout-app"}`}>
          {isMobile ? <Header setAlterFlag={setAlterFlag} /> : <HeaderPc />}
          <main>{renderRoutes(Routes)}</main>
          <Footer setShowContactUsMobile={setShowContactUsMobile} />
          {/* 右下角操作栏 */}
          {isMobile ? <SiderMenuMobile /> : <SiderMenu />}
          {showContactUsMobile && (
            <ContactUsMobile
              setShowContact={() => setShowContactUsMobile(false)}
            />
          )}
          {/* 申请试用弹窗 pc  */}
          {alterFlag ? <AlterWindow setAlterFlag={setAlterFlag} /> : null}
          {/* 移动端路由 */}
          {showMenuMobile && (
            <MenuMobile
              showMenuMobile={showMenuMobile}
              setShowMenuMobile={setShowMenuMobile}
            />
          )}
          <Toaster />
        </div>
      </CommonContextProvider>
    </>
  );
};
export default Layout;
