import { memo } from "react";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import "./index.less";

const Module1 = memo(() => {
  return (
    <div className="common-module-box marketing-module1">
      <div className="common-module-title">{data.title}</div>
      <div className="common-module-text">{data.text}</div>
      <img
        className="common-module-pic module1-pic"
        src={data.pic}
        alt={data.text}
      />
    </div>
  );
});
export default Module1;
const data = {
  title: `基于衔远通专融合大模型的一站式内容营销平台`,
  text: `面向鞋服美妆、食品饮料、金融银行等行业客户，提供简单好用的AIGC内容营销平台，深耕垂直领域营销增长，以爆款爆改为核心提供从爆款洞察、爆改创意、内容生成、转化增长的闭环服务，助力企业的数字营销、IT、媒介等团队在内容营销时代实现效能升级，扩大企业盈利`,
  pic: imageZip(
    `${CDN_PREFIX}static/frontisportal/banner/prod-build.png?time=1105`
  ),
};
