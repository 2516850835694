import Banner from "./components/banner";
import Articles from "./components/articles";
import { useSetHeader } from "../../hooks/useSetHeader";
import useViewport from "../../hooks/useViewport";
import "./index.less";

const ResourceCenter = () => {
  const { isMobile } = useViewport();
  useSetHeader({});
  return (
    <div className={`${isMobile ? 'resource-center-mobile' : 'resource-center'}`}>
      <Banner />
      <Articles />
    </div>
  );
};
export default ResourceCenter;
