import { memo } from "react";
import "./index.less";
import { all_data } from "../../data";

const Banner = memo<{ data: typeof all_data.banner }>(({ data }) => {
  return (
    <>
      <div className="common-banner-top" />
      <div
        className={`common-banner solution-scene-banner ${data.className}`}
        style={{ backgroundImage: `url(${data.bg})` }}
      >
        <div className="common-banner-bg-black">
          <div className="common-banner-main">
            <div className="common-banner-box">
              <div className="common-banner-title-sm white">{data.text[0]}</div>
              <div className="common-banner-title-sm white">
                <div className="big" data-text={data.text[1]}></div>
                <div className="normal">{data.text[2]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default Banner;
