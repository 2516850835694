import Banner from "./modules/banner";
import Module1 from "./modules/module1";
import Module2 from "./modules/module2";
import Module3 from "./modules/module3";
import "./index.less";
import "./mobile.less";
import useViewport from "../../hooks/useViewport";

const MassProducts = () => {
  const { isMobile } = useViewport();
  return (
    <div className={`fr-mass-introduce-pc${isMobile ? " phone" : ""}`}>
      <Banner />
      <Module1 />
      <Module2 />
      <Module3 />
    </div>
  );
};
export default MassProducts;
