import { memo } from "react";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";

import "./index.less";

const Banner = memo(() => {
  return (
    <>
      <div className="common-banner-top" />
      <div
        className="common-banner fr-product-marketing-banner"
        style={{ backgroundImage: `url(${data.bg})` }}
      >
        <div className="common-banner-main">
          <div className="common-banner-box">
            <div className="common-banner-title">{data.title}</div>
            <div className="common-banner-text-md">{data.text}</div>
          </div>
        </div>
      </div>
    </>
  );
});
export default Banner;
const data = {
  title: `MODI AI 营销大脑
  Frontis MODI AI Marketing Brain`,
  text: `基于衔远自研大模型的理解、洞察、决策、生成等能力，
  帮品牌企业实现AI Native的营销洞察、创意策划、内容生产以及营销转化能力，
  为时尚鞋服、食品饮料、美妆个护、金融保险等行业提供全面领先的Al营销解决方案
`,
  bg: imageZip(
    `${CDN_PREFIX}static/frontisportal/banner/product-banner.png?time=11061720`
  ),
};
