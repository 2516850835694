import useViewport from "../../../../hooks/useViewport";
import "./media.less";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
const banner = imageZip(
  `${CDN_PREFIX}static/frontisportal/banner/news-bg.png?time=1029`
);

const Banner = () => {
  const { isMobile } = useViewport();
  return (
    <div
      className={`${isMobile ? "banner-resource-mobile" : "banner-resource"}`}
    >
      <img
        className="resource-banner"
        src={banner}
        alt="行业最新前沿资讯分享"
      />
    </div>
  );
};
export default Banner;
