import { memo } from "react";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import "./index.less";

const Module2 = memo(() => {
  return (
    <div className="common-module-box maas-module2">
      <div className="common-module-title">{data.title}</div>
      <img className="common-module-pic" src={data.pic} alt={data.text} />
    </div>
  );
});
export default Module2;
const data = {
  title: `通专融合是兼具大模型泛化性、专业性和经济性的核心技术路线`,
  text: `通专融合；保持大模型的基础通用泛化性之上低成本高效率让大模型提升行业专业性水平，是最具经济性的技术路线；将大模型落地到各行各业的 高价值业务场景中；生产制造、风险控制、科学发现；通用泛化性、多任务处理能力；进展快、投资高、能耗大、专业能力提升速度需加快；行业专业性、至少达到专业人才前10%；应用针对性强、泛化能力有瓶颈`,
  pic: imageZip(`${CDN_PREFIX}static/frontisportal/maas/maas1.png?time=1104`),
};
