import { memo } from "react";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import "./index.less";

const Banner = memo(() => {
  return (
    <>
      <div className="common-banner-top" />
      <div
        className="common-banner fr-product-maas-banner"
        style={{ backgroundImage: `url(${data.bg})` }}
      >
        <div className="common-banner-main">
          <div className="common-banner-box">
            <div className="common-banner-title">{data.title}</div>
            <div className="common-banner-text-md">{data.text}</div>
          </div>
        </div>
      </div>
    </>
  );
});
export default Banner;
const data = {
  title: `衔远通专融合新一代人工智能平台产品
  加速大模型行业化价值落地`,
  text: `基于通专融合技术路线，实现低成本高效率的专业大模型打造和智能体应用开发，让千行百业获得大模型智能化加持`,
  bg: imageZip(
    `${CDN_PREFIX}static/frontisportal/banner/maas-banner.png?time=11061719`
  ),
};
