import useScrollToTop from "../../hooks/useScrollToTop";
import Immediately from "../../components/immediately";
import { useSetHeader } from "../../hooks/useSetHeader";
import "./index.less";
import "./media.less";

import Banner from "./mxx/components/banner";
import banner from "./images/banner.jpg";
import AiMain from "./components/aiMain";
import useViewport from "../../hooks/useViewport";
const Solution = () => {
  const { isMobile } = useViewport();

  useScrollToTop();
  useSetHeader({
    title: "3C行业产品创新解决方案",
    keywords: "3C创新解决方案,人群需求洞察,打造爆款,市场洞察,电商人群洞察报告",
    description:
      "随着3C行业产业升级和消费者对产品品质需求的提升，衔远通过大数据和AI技术，提供一整套完整解决方案，以消费者为中心，重构产品创新模式，塑造市场竞争力。",
  });
  return (
    <div className={`${isMobile ? "solution-page-mobile" : "solution-page"}`}>
      {/* 3c解决方案banner */}
      <Banner
        title={["食品饮料行业", "快速生成AI智能配方"]}
        desc={[
          "基于衔远ProductGPT完成智能洞察分析，通过社媒数据进行舆情监测和竞品调研，",
          "发现蓝海机会，并通过智能营销快速占领市场。",
        ]}
        style={{
          background: `#000 url(${banner})  no-repeat right bottom`,
          backgroundSize: "cover",
        }}
        // bgColor = 'linear-gradient(293deg, rgba(13,8,8,0.00) 42%, rgba(13,8,8,0.86) 67%, #0D0808 89%)  no-repeat right center'
      />
      <AiMain />
      <Immediately />
    </div>
  );
};
export default Solution;
