import { memo } from "react";
import "./index.less";
import "./mobile.less";
import { all_data } from "./data";
import Banner from "./modules/banner";
import Module1 from "./modules/module1";
import useViewport from "../../hooks/useViewport";

export const ScenePc = memo<{ data: typeof all_data }>(({ data }) => {
  const { isMobile } = useViewport();
  return (
    <div className={`scence-pc-page${isMobile ? " phone" : ""}`}>
      <Banner data={data.banner} />
      <Module1 data={data.items} />
    </div>
  );
});
