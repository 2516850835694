import { memo } from "react";
import { CDN_PREFIX } from "../../../../utils/constants";
import "./index.less";
import { imageZip } from "../../../../utils/utils";

const Module1 = memo(() => {
  return (
    <div className="common-module-box">
      <div className="common-module-title">{data.title}</div>
      <img src={data.src} alt={data.alt} className="common-module-pic" />
    </div>
  );
});
export default Module1;
const data = {
  src: imageZip(
    `${CDN_PREFIX}static/frontisportal/banner/home-module1.png?time=1029`
  ),
  title: "基于通专融合技术，衔远新一代人工智能平台加速大模型行业化价值落地",
  alt: "大小模型协同、多智能体协同、工具协同、人机交互；行业大模型、基础大模型、机器学习模型",
};
