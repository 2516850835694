import { FC, memo, useState, useContext } from "react";
import MenuMobile from "../menuMobile";
import { AppContext } from "../../../context";
import logoBlack from "../../../images/logo-black.svg";
import mediaNavIcon from "../../images/media-nav-icon.png";

const Header: FC<{
  setAlterFlag?: (flag: boolean) => void;
}> = memo(({ setAlterFlag }) => {
  const { navStyle } = useContext(AppContext);
  const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);

  return (
    <>
      <header className={`header-wrapper ${navStyle}`}>
        <div className="head-content">
          <div className="left">
            <img className="logo" src={logoBlack} alt="" />
            <img
              className="menu-btn"
              src={mediaNavIcon}
              onClick={() => setShowMenuMobile(true)}
              alt=""
            />
          </div>
        </div>
      </header>
      {/* 移动端路由 */}
      {showMenuMobile && (
        <MenuMobile
          showMenuMobile={showMenuMobile}
          setShowMenuMobile={setShowMenuMobile}
        />
      )}
    </>
  );
});

export default Header;
