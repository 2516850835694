import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import { memo } from "react";
import "./index.less";
const banner = imageZip(
  `${CDN_PREFIX}static/frontisportal/banner/join-us-bg.png`
);
const Banner = memo(() => {
  return (
    <>
      <div className="common-banner-top" />
      <div
        className="common-banner join-us-banner"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="common-banner-main">
          {`同创未来，
          寻找发光的你`}
        </div>
      </div>
    </>
  );
});
export default Banner;
