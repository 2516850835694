import { useHistory } from "react-router-dom";
import GroundForm from "./groundForm";
import { BASE_ROUTE_NAME } from "../../config";
import useViewport from "../../hooks/useViewport";
// 头部header
import logo from "./images/logo.png";
import contact from "./images/contact.png";
// 面向企业的创新业务场景解决方案
import productChart from "./images/productChart.png";
import productChartMobile from "./images/productChartMobile.png";
import dataChart from "./images/dataChart.png";
import dataChartMobile from "./images/dataChartMobile.png";
import groundNum from "./images/groundNum.png";
import groundNumMobile from "./images/groundNumMobile.png";
import solutionLeftImg from "./images/solutionLeftImg.png";
import solutionLeftImgMobile from "./images/solutionLeftImgMobile.png";
import solutionRightImg from "./images/solutionRightImg.png";
// 添加微信群
import wxCode from "./images/wxCode.png";
// 底部banner
import drawbackIcon from "./images/drawbackIcon.png";
import opportunityIcon from "./images/opportunityIcon.png";
import marketingIcon from "./images/marketingIcon.png";
import playIcon from "./images/playIcon.png";
import { useSetHeader } from "../../hooks/useSetHeader";
import "./index.less";
import "./media.less";

const GroundPage = () => {
  const history = useHistory();
  const { isMobile } = useViewport();
  useSetHeader({});
  return (
    <div className={isMobile ? "ground-page-mobile" : "ground-page"}>
      <div className="ground-header">
        <img
          src={logo}
          onClick={() =>
            history.push(
              BASE_ROUTE_NAME.trim() !== ""
                ? BASE_ROUTE_NAME
                : `/${BASE_ROUTE_NAME}`
            )
          }
          alt=""
          className="ground-logo"
        />
        <div className="ground-contact">
          <img src={contact} alt="" />
        </div>
      </div>
      {/* 顶部banner */}
      <div className="ground-banner">
        <div className="ground-banner-content">
          <div className="ground-title-children">
            <span>ForeSight</span> | 产品参谋
          </div>
          <div className="ground-title">
            国内首个{!isMobile && <br />}
            <span>产品创新</span>AI智能服务平台
          </div>
          <p>
            为企业解决产品创新难、产品成功率低、企划效率低的问题，助力企业持续打造爆品
          </p>
          <GroundForm headerText="免费申请试用" />
        </div>
      </div>
      {/* ForeSight | 产品参谋 */}
      <div className="fore-sight">
        <div className="fore-sight-title">ForeSight | 产品参谋</div>
        <div className="fore-sight-top">
          <img src={isMobile ? productChartMobile : productChart} alt="" />
          <p>
            以C2B思想为原则，产品企划IPD流程方法为指导，人工智能算法技术为支撑，通过衔远积累的对市场、消费者、竞争对手、产业的广域数据与洞察模型，结合衔远独有专利的产品企划AI引擎，为企业产品创新团队提供行业智能调研，产品创新机会与趋势发现，AI智能推荐最佳产品参数组合，产品资产库、Charter协同，产品上市验证等功能板块，批量化地帮助客户持续提升产品竞争力，增强盈利能力和运作效率。
          </p>
        </div>
        <div className="fore-sight-bottom">
          <img src={isMobile ? dataChartMobile : dataChart} alt="" />
          <img src={isMobile ? groundNumMobile : groundNum} alt="" />
        </div>
      </div>
      {/* 面向企业的创新业务场景解决方案 */}
      <div className="ground-solution">
        <div className="ground-solution-header">
          面向企业的{isMobile && <br />}创新业务场景解决方案
        </div>
        <div className="ground-solution-content">
          <div className="ground-solution-left solution-all-style">
            <img
              src={isMobile ? solutionLeftImgMobile : solutionLeftImg}
              alt=""
            />
            <div>
              <span>快消行业</span>解决方案<i></i>
            </div>
            <p>助力食品饮料企业把握行业风向，打造爆品</p>
            <span className="solution-text-mobile">
              实时分析食品饮料市场数据，感知市场动向，快速发现、定位消费者的关注热点和需求变化，助力本品的新品研发，快速发现蓝海机会，抢占市场。
            </span>
            <button
              onClick={() =>
                history.push(`${BASE_ROUTE_NAME}/solution/ft`)
              }
              className="contact-btn"
            >
              <span>了解详情</span>
            </button>
          </div>
          <div className="ground-solution-right solution-all-style">
            <img src={solutionRightImg} alt="" />
            <div>
              <span>3C行业</span>解决方案
            </div>
            <p>助力家居{"&"}家具企业重构产品创新模式，塑造市场竞争力</p>
            <span className="solution-text-mobile">
              运用人工智能、大数据等技术为3C行业客户提供基于市场、人群、场景、技术的创新企划解决方案，实时洞察产品创新机会，助力企业持续打造爆品。
            </span>
            <button
              onClick={() => history.push(`${BASE_ROUTE_NAME}/solution/fb`)}
              className="contact-btn"
            >
              <span>了解详情</span>
            </button>
          </div>
        </div>
      </div>
      {/* 添加微信群 */}
      <div className="ground-solution-advertisement">
        <div className="ground-advertisement-content">
          <div className="ground-advertisement-left">
            <div>
              食品饮料、家居{"&"}家具行业<span>微信群</span>
            </div>
            <p>让专业的人聚在一起</p>
            <span>
              目前已建立【Frontis智能家居群】&【Frontis食品饮料群】，相关头部公司已入驻，欢迎扫码加客服进群交流。
            </span>
          </div>
          <div className="ground-advertisement-right">
            <img src={wxCode} alt="" />
            <p>请备注“姓名+公司+职位”</p>
          </div>
        </div>
      </div>
      {/* 底部banner */}
      <div className="ground-footer">
        <div className="ground-footer-content">
          <div className="ground-footer-text">
            <i></i>
            <div>
              <p>行业诊断活动</p>
              <span>食品饮料、家居{"&"}家具行业</span>
            </div>
          </div>
          <ul className="ground-footer-list">
            <li>
              <img src={drawbackIcon} alt="" />
              <p>发现产品槽点</p>
            </li>
            <li>
              <img src={opportunityIcon} alt="" />
              <p>发现场景机会</p>
            </li>
            <li>
              <img src={marketingIcon} alt="" />
              <p>挖掘营销卖点</p>
            </li>
          </ul>
          <div className="ground-footer-play">
            <div className="ground-play-pading">
              <p>欢迎报名填写信息，我们针对您的品类，提供免费诊断解决方案</p>
              <img src={playIcon} alt="" />
            </div>
          </div>
          <GroundForm
            headerText="免费申请试用"
            // headerText={<span style={{ fontSize: '0.18rem' }}>欢迎咨询<br />免费诊断解决方案</span>}
            titleHeight={{ height: "1rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default GroundPage;
