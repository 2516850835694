export const { REACT_APP_APP_ENV } = process.env;
export const BASE_ROUTE_NAME = ["staging", "prod"].includes(
  REACT_APP_APP_ENV as string
)
  ? ""
  : "/frontis-portal";
// export const BASE_ROUTE_NAME = "";

export function getQueryString(name: string, url?: string) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\\[\\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }
  return results[2].replace(/\+/g, " ");
}
