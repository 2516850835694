import { useState } from "react";
import AlterWindow from "../../layout/components/alterWindow";
import useViewport from "../../hooks/useViewport";
import ContactFormMobile from "../../layout/components/contactFormMobile";

const ApplyBtn = () => {
  const { isMobile } = useViewport();
  const [alterFlage, setAlterFlag] = useState(false);
  return (
    <>
      {!isMobile && (
        <div
          className={isMobile ? "product-btn-mobile" : "product-btn"}
          onClick={() => setAlterFlag(true)}
        >
          <span>申请试用</span>
        </div>
      )}

      {alterFlage ? (
        isMobile ? (
          <ContactFormMobile setShowContactForm={setAlterFlag} />
        ) : (
          <AlterWindow setAlterFlag={setAlterFlag} />
        )
      ) : null}
    </>
  );
};

export default ApplyBtn;
