import Banner from "./components/banner";
import Innovate from "./components/innovate";
import ProductHighLights from "./components/highlights";
import { useSetHeader } from "../../hooks/useSetHeader";
import useViewport from "../../hooks/useViewport";
import "./index.less";

const ProductIntroductionMobile = () => {
  const { isMobile } = useViewport();
  useSetHeader({
    title: "产品参谋，首个AI驱动的CIP协同创新平台",
    keywords: "产品参谋,CIP,AI协同创新平台,新品创新中心,产品企划,市场洞察",
    description:
      "产品参谋是一款为产品企划从业者及企业，提供市场洞察、产品定位、产品概念设计等产品创新服务的生产力工具与数智化协同平台。",
  });
  return (
    <div
      className={`product-introduction ${
        isMobile ? "product-introduction-mobile" : ""
      }`}
    >
      {/* 产品介绍banner */}
      <Banner />
      {/* AI助力产品创新 */}
      <Innovate />
      {/* 产品功能亮点 */}
      <ProductHighLights />
    </div>
  );
};
export default ProductIntroductionMobile;
