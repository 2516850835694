import React, { memo, useState } from "react";
import "./index.less";
import { data, module2data } from "./data";
import check from "../../images/check.svg";
import BigIconTabs from "../../../../components/bigIconTabs";
import SmallTabs from "../../../../components/smallTabs";
import { CDN_PREFIX } from "../../../../utils/constants";
import { imageZip } from "../../../../utils/utils";
const imgBaseSrc = `${CDN_PREFIX}static/moxiaoxian/home/`;
const videoUrl =
  "https://frontis-public-1313914583.cos.ap-beijing.myqcloud.com/cdn-content/static/moxiaoxian/home/video.mp4";
const Module2 = memo(() => {
  const [active, setActive] = useState(0);
  const [subActive, setSubActive] = useState<string[]>([]);

  return (
    <div className="common-module-box common-module-padding-bottom marketing-module2">
      <div className="common-module-title">{data.title}</div>
      <BigIconTabs options={data.tabs} active={active} onChange={setActive} />
      <div className="tab-content">
        {module2data.map((item, i) => {
          let activeNum = Number(subActive[i]) || 0;
          return (
            <div
              className={`tab-content-item${active === i ? " show" : ""}`}
              key={item.title}
            >
              <div className={`left-box`}>
                <div className="tab-content-title">{item.title}</div>
                <div className="tab-content-text">{item.desc}</div>
                <div className="tag-box">
                  {item.tags.map((tag) => (
                    <span className="tag" key={tag}>
                      <img src={check} alt="" />
                      {tag}
                    </span>
                  ))}
                </div>
                {item.url[activeNum] ? (
                  <a
                    rel="noreferrer"
                    className="common-primary-btn"
                    href={`https://www.moxiaoxian.art${item.url[activeNum]}${
                      item.url[activeNum].includes("?") ? "&" : "?"
                    }login=1`}
                    target="_blank"
                  >
                    立即体验
                  </a>
                ) : (
                  <div className="common-primary-btn">敬请期待</div>
                )}
              </div>
              <div className="right-box img-box">
                {item.imgs.map(
                  (_, subIndex) =>
                    activeNum === subIndex && (
                      <React.Fragment key={subIndex}>
                        {item.items[Number(subIndex)]?.label !== "视频生成" ? (
                          <img
                            src={imageZip(
                              `${imgBaseSrc}${item.imgs[subIndex]}.png`
                            )}
                            alt=""
                          />
                        ) : (
                          <video
                            id={"视频生成"}
                            className="gif-img"
                            autoPlay
                            loop
                            muted
                            playsInline
                            poster={imgBaseSrc + "img14.png"}
                            x5-video-player-type="h5"
                            webkit-playsinline="true"
                            x-webkit-airplay="true"
                            x5-video-player-fullscreen="true"
                            src={videoUrl}
                          />
                        )}
                      </React.Fragment>
                    )
                )}
                <div className="tab-box">
                  <SmallTabs
                    options={item.items}
                    active={subActive[i] || item.items[0].key}
                    onChange={(v) => {
                      const _subActive = [...subActive];
                      _subActive[i] = v;
                      setSubActive([..._subActive]);
                    }}
                    trigger="hover"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
export default Module2;
