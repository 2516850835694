export interface IHeaderInfo {
  title: string;
  description: string;
  keywords: string;
}
export const setHeader = ({ title, description, keywords }: IHeaderInfo) => {
  document.title = title;
  const _meta = document.getElementsByTagName("meta");
  for (let i = 0, len = _meta.length; i < len; i++) {
    switch (_meta[i].name) {
      case "keywords":
        _meta[i].content = keywords;
        break;
      case "description":
        _meta[i].content = description;
        break;
      default:
        break;
    }
  }
};
