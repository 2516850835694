import { createContext, memo, ReactNode, useState } from "react";
import ServiceWindow from "../layout/components/serviceWindow";

interface ICommonContext {
  showService?: "click" | "hover" | "";
  setShowService?: (v: "click" | "hover" | "") => void;
}
export const CommonContext = createContext<ICommonContext>({});
export const CommonContextProvider = memo<{ children?: ReactNode }>(
  ({ children }) => {
    const [showService, setShowService] = useState<"click" | "hover" | "">("");
    return (
      <CommonContext.Provider value={{ showService, setShowService }}>
        {children}
        {/* 咨询与合作 pop */}
        <ServiceWindow show={showService} onClose={() => setShowService("")} />
      </CommonContext.Provider>
    );
  }
);
