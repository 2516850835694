import { memo } from "react";
import Banner from "./modules/banner";
import Module1 from "./modules/module1";
import Module2 from "./modules/module2";
// import Module3 from "./modules/module3";
import useViewport from "../../hooks/useViewport";
import "./mobile.less";

const Science = memo(() => {
  const { isMobile } = useViewport();
  return (
    <div className={`fr-science-pc${isMobile ? " phone" : ""}`}>
      <Banner />
      <Module1 />
      <Module2 />
      {/* <Module3 /> */}
    </div>
  );
});
export default Science;
