import useViewport from '../../../../hooks/useViewport';
import banner from './images/banner.jpg';
import './media.less';
import './index.less';

const Banner = () => {
  const { isMobile } = useViewport();
  return <div className={isMobile ? 'product-banner-mobile' : 'product-banner'}>
    <img src={banner} className='p-banner' alt="ProductGPT,专业的人机协同交互平台,基于衔远品商大模型构建符合企业业务场景的agent应用，助力企业实现从商机发现到产品交付的 全链路数智化转型，实现企业真正的高价值场景应用" />
    <div className='banner-content'>
      <h1>ProductGPT,</h1>
      <h1>专业的人机协同交互平台</h1>
      {!isMobile && <p>基于衔远品商大模型构建符合企业业务场景的agent应用，助力企业实现从商机发现到产品交付的 全链路数智化转型，实现企业真正的高价值场景应用</p>}
    </div>
  </div>
}
export default Banner;