import { FC, memo } from "react";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
const Module3: FC = memo(() => {
  return (
    <div
      className="common-banner join-us-module3"
      style={{
        backgroundImage: `url(${imageZip(
          CDN_PREFIX + "static/frontisportal/banner/join-us-bot-bg.png"
        )})`,
      }}
    >
      <div className="common-banner-main">
        <div className="common-banner-box">
          <div className="common-banner-title">
            {`衔远快速发展中，
            期待您的加入！`}
          </div>
          <div className="common-banner-text">
            在未来十年最有确定性的赛道上，我们期待您的加入，让我们一起努力用技术为社会创造更多的价值！公司提供极具竞争力的薪酬待遇和广阔的成长空间，让每一位衔远人和公司一起分享高速成长的回报！
          </div>
        </div>
      </div>
    </div>
  );
});
export default Module3;
