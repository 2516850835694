import { memo, useContext } from "react";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import { CommonContext } from "../../../../common/commonContext";
const Module5 = memo(() => {
  const { setShowService, showService } = useContext(CommonContext);
  return (
    <div
      className="common-module-1440 common-module-padding60 home-module5"
      style={{
        backgroundImage: `url(${data.bg})`,
      }}
    >
      <div className="common-module-title">{data.title}</div>
      <div className="common-module-text">{data.text}</div>
      <div className="common-module-bot">
        <div
          className="common-primary-btn big"
          onClick={() => setShowService?.(showService ? "" : "click")}
        >
          合作咨询
        </div>
      </div>
    </div>
  );
});
export default Module5;
const data = {
  title: "专业咨询，合作共赢",
  text: "加速赋能企业，与衔远一起探索数智化",
  bg: imageZip(`${CDN_PREFIX}static/frontisportal/banner/join-bg.png`),
};
