import { FC, memo } from "react";
import "./index.less";
import "./media.less";
import banner from "../../images/banner.jpg";
import useViewport from "../../../../../hooks/useViewport";
interface IBanner {
  title: string[];
  desc: string[];
  style?: object;
  bgColor?: string;
}

const Banner: FC<IBanner> = memo(({ title, desc, style = {}, bgColor }) => {
  const { isMobile } = useViewport();

  return (
    <div
      id="banner"
      className={`${isMobile ? "solution-banner-mobile" : "solution-banner"}`}
      style={{ backgroundImage: `url(${banner})`, ...style }}
    >
      <div
        className="bg-box"
        style={{
          background: bgColor || "",
        }}
      >
        <div className="w1200">
          {title.map((title) => (
            <h3 key={title}>{title}</h3>
          ))}

          {!isMobile && (
            <p>
              {desc.map((desc) => (
                <span key={desc}>
                  <span>{desc}</span>
                  <br></br>
                </span>
              ))}
            </p>
          )}
        </div>
      </div>
    </div>
  );
});
export default Banner;
