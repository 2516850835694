import { memo, createContext, ReactNode, useState } from "react";

export interface IAppContext {
  navStyle?: 'black' | 'white';
  setNavStyle?: (navStyle: 'black' | 'white') => void;
}
export const AppContext = createContext<IAppContext>({})
const AppContextProvider = memo<{ children: ReactNode }>(({ children }) =>  {
  const [navStyle, setNavStyle] = useState<'black' | 'white'>('white');
  return (
    <AppContext.Provider value={{ navStyle, setNavStyle }}>
      {children}
    </AppContext.Provider>
  )
})
export default AppContextProvider;