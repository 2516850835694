import { memo } from "react";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";
import arrow from "../../images/arrow-icon.png";
import { useHistory } from "react-router-dom";
import { BASE_ROUTE_NAME } from "../../../../config";
import useViewport from "../../../../hooks/useViewport";

const Module4 = memo(() => {
  const history = useHistory();
  const { isMobile } = useViewport();
  return (
    <div className="common-module-1440 common-module-padding-top home-module4">
      <div className="common-module-title">{data.title}</div>
      <div className="module4-content">
        <div className="module4-left">
          <div className="left-title">{data.left.title}</div>
          <div className="left-big">{data.left.big}</div>
          <div className="left-line" />
          <div className="left-text">{data.left.text}</div>
        </div>
        <div className="module4-list">
          {data.list.map((child) => (
            <div
              key={child.title}
              className="module4-col"
              onClick={() =>
                isMobile &&
                child.link &&
                history.push(`${BASE_ROUTE_NAME}${child.link}`)
              }
              style={{
                backgroundImage: `url(${imageZip(`${CDN_PREFIX}${child.bg}`)})`,
              }}
            >
              <img
                className="col-pic"
                src={imageZip(`${CDN_PREFIX}${child.bg}`)}
                alt=""
              />
              <div className="col-main">
                <div className="hover-box">
                  <div
                    className={`arrow${child.link ? "" : " disabled"}`}
                    onClick={() =>
                      !isMobile &&
                      child.link &&
                      history.push(`${BASE_ROUTE_NAME}${child.link}`)
                    }
                  >
                    <img src={arrow} className="arrow-icon" alt={child.title} />
                    <span className="coming-soon-tips">敬请期待</span>
                  </div>
                  <div className="hover-text">{child.text}</div>
                  <div className="hover-line" />
                </div>
                <div className="col-title">{child.title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
export default Module4;
const data = {
  title: "满足更多行业解决方案",
  left: {
    title: "满足多种业务需求的行业解决方案",
    big: "行业解决方案",
    text: `沉淀细分行业know-how
    从咨询的角度帮助客户解决问题`,
  },
  list: [
    {
      title: "科学发现解决方案",
      link: "/solution/science",
      bg: "static/frontisportal/banner/science.png?time=1029",
      text: "基于科研专业大模型提出科学假设，再使用工具协同和人机协同等构建智能体做假设的可靠性验证，从而助力科学发现",
    },
    {
      title: "供应链解决方案",
      bg: "static/frontisportal/banner/supplyChain.png",
      text: "风险管控能力是大宗交易供应链企业保持供应链运营业务健康发展的核心竞争力，风控专业大模型通过对结构化数据、非结构化数据和时序数据的高质量学习，显著提升供应链企业风控能力",
    },
    {
      title: "建筑行业解决方案",
      bg: "static/frontisportal/banner/construction.png",
      text: "基于项目施工组织文件等信息实现计划自动编制，计划执行过程中从人、机、料、法、环各部分实时获取项目动态信息，进行风险的自动预警和纠偏，辅助项目管理人员高效提升工作效率、动态管控计划风险",
    },
    // {
    //   title: "食品饮料",
    //   link: "/solution/fb",
    //   bg: "static/frontisportal/banner/consumption.png",
    //   text: "基于衔远ProductGPT完成智能洞察分析，通过社媒数据进行舆情监测和竞品调研，发现蓝海机会，并通过智能营销快速占领市场。",
    // },
    // {
    //   title: "快消零售",
    //   link: "/solution/fmcg",
    //   bg: "static/frontisportal/banner/3C.png",
    //   text: "基于衔远ProductGPT完成智能洞察分析，通过社媒数据进行舆情监测和竞品调研，发现蓝海机会，并通过智能营销快速占领市场。",
    // },
  ],
};
