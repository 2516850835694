import Banner from "./modules/banner";
import Module1 from "./modules/module1";
import Module2 from "./modules/module2";
import Module3 from "./modules/module3";
import Module4 from "./modules/module4";
import "./mobile.less";
import useViewport from "../../hooks/useViewport";

const AboutUsPc = () => {
  const { isMobile } = useViewport();
  return (
    <div className={`fr-pc-about-us${isMobile ? " phone" : ""}`}>
      <Banner />
      <Module1 />
      <Module2 />
      <Module3 />
      <Module4 />
    </div>
  );
};
export default AboutUsPc;
