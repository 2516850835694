import { memo } from "react";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";

const Module3 = memo(() => {
  return (
    <div className="fr-pc-about-us-module3">
      <img
        src={imageZip(
          `${CDN_PREFIX}static/frontisportal/introduce/introduce-time-line.png?time=1104`
        )}
        alt="2021衔远创立、2022.01管理团队组建、2022.06自研AI驱动产品创新协同平台、2022.12获得蒙牛“牛客松”创新技术大赛奖项，完成产品验证、2023.03完成数亿元天使轮融资、2023.05入选36氪下一个百亿规模 AIGC产品、2023.06入选北京市首批人工智能行业大模型应用案例、2023.07当选“北京市通用人工智能 产业创新伙伴计划”模型观察员"
      />
    </div>
  );
});
export default Module3;
