import { imageZip } from "../../utils/utils";
import { CDN_PREFIX } from "../../utils/constants";
export const all_data = {
  banner: {
    className: "all",
    bg: imageZip(
      CDN_PREFIX + "static/frontisportal/solution-scene/banner-all.png"
    ),
    text: ["让员工成为品牌最坚实的拥护者，", "10x速", "提升品牌社媒影响力"],
  },
  items: [
    {
      pic: imageZip(
        CDN_PREFIX + "static/frontisportal/solution-scene/all-pic1.png"
      ),
      title: "社交媒体是品牌推广关键，",
      subTitle: "助力员工内容创作",
      list: [
        "采集员工的各类相关信息，这些信息包括但不限于员工的专业技能、兴趣爱好、工作经历等",
        "依据人设定位的规则，迅速且精准地为员工匹配其所在赛道的最优人设定位",
        "综合多方面因素，为员工推荐热门选题",
      ],
    },
    {
      pic: imageZip(
        CDN_PREFIX + "static/frontisportal/solution-scene/all-pic2.png"
      ),
      title: "品牌宣传依赖高质量内容，",
      subTitle: "提升内容创作质量",
      list: [
        "衔远特有的“通专融合”大模型能够发挥重要的辅助创作作用。大模型具有先进的算法和智能分析能力，能够有效避免产生负面内容。无论是人工方式创作的内容，还是借助大模型创作的内容，都能够支持提交到总部进行审核",
        "在审核过程中，会先以大模型对内容进行自动化检测，大模型能够识别内容中的各种潜在风险，如语义上的歧义、可能存在的违规表述等",
        "配合人工进行二次审核，人工审核能够从更全面、更细致的角度对内容进行审查，考虑到一些特殊情况、文化背景、品牌理念等因素。通过这种自动化检测与人工二次审核相结合的方式，从而能够全方位、无死角地杜绝风险，确保发布的内容既符合法律法规要求，又能够积极正面地展示品牌形象，为品牌发展保驾护航",
      ],
    },
    {
      pic: imageZip(
        CDN_PREFIX + "static/frontisportal/solution-scene/all-pic3.png"
      ),
      title: "海量内容效果数据难掌控，",
      subTitle: "在线集中管控海量内容数据",
      list: [
        "智能收集员工账号互动数据，包括点赞数、分享数、转发、评论等。将获取的互动和评论数据统一展示，能够清晰汇总情况。对 CPE 表现高于基准 (benchmark) 的内容，有针对性投流，打造爆款内容",
        "深入分析评论中与品牌、产品相关内容，洞悉消费者需求，积极回应消费者的问题和建议，提高转化率，将更多的潜在消费者转化为实际购买者",
      ],
    },
  ],
};

export const expert_data = {
  banner: {
    className: "expert",
    bg: imageZip(
      CDN_PREFIX +
        "static/frontisportal/solution-scene/banner-expert.png?time=1029"
    ),
    text: ["挖掘达人价值洼地，打通品牌与 KOC 通路", "10%", "成本撬动社媒流量"],
  },
  items: [
    {
      pic: imageZip(
        CDN_PREFIX + "static/frontisportal/solution-scene/expert-pic1.png"
      ),
      title: "助力品牌与 KOC 合作，",
      subTitle: "塑造核心人设，提升内容创作力",
      list: [
        "借助AI大模型的强大功能，辅助品牌生产科学详细的brief",
        "辅助 KOC 拆解 brief，结合设定人设，创作图文笔记以及短视频，提高KOC内容创作效率，减轻品牌方与KOC沟通的投入",
      ],
    },
    {
      pic: imageZip(
        CDN_PREFIX + "static/frontisportal/solution-scene/expert-pic2.png"
      ),
      title: "助力品牌制定 KOC 策略，",
      subTitle: "精准高效定位 KOC 属性",
      list: [
        "通过商品属性及brief要求，分析商品目标人群及适合的推广人设定位",
        "进一步推荐典型 KOC ，帮助品牌快速找到合适的推广达人",
      ],
    },
    {
      pic: imageZip(
        CDN_PREFIX + "static/frontisportal/solution-scene/expert-pic3.png"
      ),
      title: "助力品牌实时掌握 KOC 发布数据，",
      subTitle: "分析数据，洞察需求，提升转化",
      list: [
        "KOC 发布图文或短视频之后，对数据进行持续监控，助力品牌及时复盘及优化动作",
        "及时关注到潜在爆款内容，追加投流。同时对评论区进行维护，提高转化率",
      ],
    },
  ],
};
export const private_data = {
  banner: {
    className: "private",
    bg: imageZip(
      CDN_PREFIX + "static/frontisportal/solution-scene/banner-private.png"
    ),
    text: ["破除死群魔咒，打造活跃、复购私域", "5倍", "人效提升"],
  },
  items: [
    {
      pic: imageZip(
        CDN_PREFIX + "static/frontisportal/solution-scene/private-pic1.png"
      ),
      title: "避免社群互动消退，",
      subTitle: "打造会员服务中心，多维互动",
      list: [
        "私域不应是另一个电商渠道，而应当定位为会员服务中心。需要传播多维度内容，通过互动方式引导会员参与内容贡献。例如，知识科普、专家问答、互动游戏、线下活动等，达成身份认同、兴趣认同、利益认同",
      ],
    },
    {
      pic: imageZip(
        CDN_PREFIX + "static/frontisportal/solution-scene/private-pic2.png"
      ),
      title: "私域运营需提供充足服务，",
      subTitle: "AI 工具破局提升人效",
      list: [
        "许多品牌的私域运营往往陷入恶性循环：业绩越差，投入越少；投入越少，业绩越差。打破这种局面的关键在于借助 AI 工具替代人工快速回复高频问题、专业问题，实现人员效率的最大化提升",
      ],
    },
    {
      pic: imageZip(
        CDN_PREFIX + "static/frontisportal/solution-scene/private-pic3.png"
      ),
      title: "私域服务专业度要求高，",
      subTitle: "AI 工具助力提升专业度",
      list: [
        "私域运营需要大量内容。许多企业并未积累内容，且员工内容创作能力差。助力品牌打造内容 “中央厨房”，持续不断地为团队提供专业内容，并且这些内容必须与业务场景关联，不是简单促销、问候等",
      ],
    },
  ],
};
