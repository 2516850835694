import { memo } from "react";
import "./index.less";
import { imageZip } from "../../../../utils/utils";
import { CDN_PREFIX } from "../../../../utils/constants";

const Module4 = memo(() => {
  return (
    <div className="common-module-box fr-pc-about-us-module4">
      <div className="common-module-title">{data.title}</div>
      <div className="common-module-row-list gap25 col4 honors">
        {data.images.map((child) => (
          <div className="col honor" key={child.src}>
            <img src={imageZip(`${CDN_PREFIX}${child.src}`)} alt={child.text} />
            <div className="honor-black">{child.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
});
export default Module4;
const data = {
  title: "行业认可和荣誉",
  images: [
    {
      src: "static/frontisportal/introduce/honor/introduce-honor1.png",
      text: "新锐100企业",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor18.png",
      text: "下一代百亿规模AIGC产品",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor2.png",
      text: "2024 年 AIGC 产品创新榜",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor3.png",
      text: "2024 年 AIGC 创新企业榜",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor4.png",
      text: "北京市通用人工智能大模型场景应用典型案例",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor5.png",
      text: "北京市通用人工智能产业创新伙伴计划（模型伙伴）",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor6.png",
      text: "世界人工智能大会SAIL奖Top30榜单",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor7.png",
      text: "2023中国人工智能零售/电商企业Top10",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor8.png",
      text: "AGI独角兽TOP100",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor9.png",
      text: "首都互联网协会会员单位",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor10.png",
      text: "2023年最值得关注AIGC公司",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor11.png",
      text: "大模型新锐9",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor12.png",
      text: "2022-2023企业服务最具投资价值公司TOP10",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor13.png",
      text: "北京市通用人工智能产业创新伙伴计划第二批成员-模型观察员",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor14.png",
      text: "北京市通用人工智能大模型行业应用典型场景案例",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor15.png",
      text: "北京人工智能行业赋能典型案例（2023）",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor16.png",
      text: "北京市首批行业大模型典型应用案例",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor17.png",
      text: "2022中国潜在独角兽企业",
    },

    {
      src: "static/frontisportal/introduce/honor/introduce-honor19.png",
      text: "中关村高新技术企业",
    },
    {
      src: "static/frontisportal/introduce/honor/introduce-honor20.png",
      text: "2022年中国云生态创新企业",
    },
  ],
};
