import { memo, useContext, useEffect } from "react";
import { AppContext } from "./../../context";
import Banner from "./modules/banner";
import Module1 from "./modules/module1";
import Module2 from "./modules/module2";
import "./pc.less";
import "./mobile.less";
import Module3 from "./modules/module3";
import Module4 from "./modules/module4";
import Module5 from "./modules/module5";
import useViewport from "../../hooks/useViewport";
const HomePc = memo(() => {
  const { isMobile } = useViewport();
  const { setNavStyle } = useContext(AppContext);
  const navActives = () => {
    const scrollTop = getScrollTop();
    if (scrollTop > 10) {
      setNavStyle!("black");
    } else {
      setNavStyle!("white");
    }
  };

  const getScrollTop = () => {
    let scroll_top = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
      scroll_top = document.documentElement.scrollTop;
    } else if (document.body) {
      scroll_top = document.body.scrollTop;
    }
    return scroll_top;
  };
  useEffect(() => {
    navActives();
    window.addEventListener("scroll", navActives);
    return () => {
      window.removeEventListener("scroll", navActives);
    };
  });

  return (
    <div className={`home-pc-warp${isMobile ? " phone" : ""}`}>
      <div className="home-page">
        <Banner />
        <Module1 />
        <Module2 />
        <Module3 />
        <Module4 />
        <Module5 />
      </div>
    </div>
  );
});
export default HomePc;
