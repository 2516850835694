import { memo } from "react";
import Banner from "./modules/banner";
import Module1 from "./modules/module1";
import Module2 from "./modules/module2";

const ProductIntroductionPc = memo(() => {
  return (
    <div className="fr-product-introduce-pc">
      <Banner />
      <Module1 />
      <Module2 />
    </div>
  );
});
export default ProductIntroductionPc;
