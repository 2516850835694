import { FC, memo, useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import useViewport from "../../../../hooks/useViewport";
import { scrollTop } from "../../../../utils/scroll";
import { BASE_ROUTE_NAME } from "../../../../config/index";
import { REACT_APP_REQUEST_URL } from "../../../../env";
import "./index.less";
import "./media.less";

// const ARTICLE_TAG_MAPPER = [
//   { label: '全部文章', value: '' },
//   { label: '衔远荣誉', value: 'honoraryTitles' },
//   { label: 'AI新周刊', value: 'aiNewWeekly' },
//   // { label: '技术干货', value: 'technicalDryGoods' },
//   { label: '媒体报道', value: 'mediaCoverage' },
// ];
// NEWS("近期动态"),
// INFO("公司资讯"),
// NEWS_LETTER("官方快讯"),
// PRODUCT_INTRODUCE("产品介绍"),
// TECHNICAL_SHARE("技术干货"),
// PROJECT_CASE("项目案例"),
// XIANYUAN_ACTIVITY("企业活动"),

const ARTICLE_TAG_MAPPER = [
  { label: "全部文章", value: "" },
  { label: "了解产品", value: "PRODUCT_INTRODUCE" },
  { label: "技术干货", value: "TECHNICAL_SHARE" },
  { label: "项目案例", value: "PROJECT_CASE" },
  { label: "企业新闻", value: "XIANYUAN_ACTIVITY" },
  { label: "行业洞察", value: "INDUSTRY_INSIGNT" },
];
const Articles: FC = memo(() => {
  const { isMobile } = useViewport();
  const [active, _setActive] = useState("");
  const [newsList, setNewsList] = useState<Array<any>>([]);

  const getNewList = useCallback(async (v: string) => {
    let url = `${REACT_APP_REQUEST_URL}/newsinfo?status=PUBLISHED&pageNum=1&pageSize=100`;
    if (v) url += `&region=${v}`;
    const res = await axios.get(url);
    const records =
      res?.data?.data?.records.filter((item: any) => item.status.code) || [];
    setNewsList(records);
    return records;
  }, []);
  const activePos = useRef<{ active?: string; id?: string }>({});
  const setActive = useCallback(
    (v: string) => {
      _setActive(v);
      getNewList(v).then((res) => {
        if (activePos.current.id) {
          setTimeout(() => {
            document
              .querySelector(`[data-id="${activePos.current.id}"]`)
              ?.scrollIntoView({ block: "center" });
          }, 0);
        }
      });
    },
    [getNewList]
  );
  useEffect(() => {
    activePos.current = JSON.parse(
      sessionStorage.getItem("ACTIVE_POS") || "{}"
    );
    sessionStorage.removeItem("ACTIVE_POS");
    setActive(activePos.current.active || "");
  }, []);

  // ACTIVE_POS
  return (
    <div
      className={`articles-component ${
        isMobile ? "articles-component-mobile" : ""
      }`}
    >
      <div className="article-tabs">
        {ARTICLE_TAG_MAPPER.map((item) => {
          return (
            <div
              key={item.value}
              className={`article-tab${active === item.value ? " active" : ""}`}
              onClick={() => setActive(item.value)}
            >
              {item.label}
            </div>
          );
        })}
      </div>
      <div className="article-wrapper">
        {newsList.length ? (
          <div className="article-lists">
            {newsList.map((item) => {
              return <Article key={item.id} article={item} active={active} />;
            })}
          </div>
        ) : (
          <div className="article-empty">暂无内容</div>
        )}
      </div>
    </div>
  );
});

const Article: FC<{
  article: any;
  active?: string;
}> = memo(({ article, active }) => {
  const history = useHistory();
  const { isMobile } = useViewport();

  return (
    <div
      className="article-item"
      data-id={article.id}
      onClick={() => {
        history.push(`${BASE_ROUTE_NAME}/news/detail/` + article.id);
        scrollTop();
        sessionStorage.setItem(
          "ACTIVE_POS",
          JSON.stringify({ id: article.id, active })
        );
      }}
    >
      <div
        className="cover"
        style={{ backgroundImage: `url(${article.coverPlan})` }}
      />
      <div className="right">
        <div className="title">{article.title}</div>
        <div className="summary">{article.summary}</div>
        <div className="infos">
          <div className="date">
            <span className="iconfont icon-time"></span>
            {isMobile
              ? article.createTime?.substring(0, 10)
              : article.createTime}
          </div>
          {/* <div className="read-num">
            <span className="iconfont icon-eyes"></span>
            {article.readCount}
          </div> */}
        </div>
      </div>
    </div>
  );
});

export default Articles;
